import { call, put, takeEvery } from "redux-saga/effects"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {
    GET_TASKS_TYPES,
    FETCH_PARENT_TASKTYPES,
    ADD_NEW_TASK_TYPES,
    GET_TASKTYPE_CONFIG_LIST,
    ADD_TASK_TYPE_CONFIG,
    DELETE_TASK_TYPES,
    DELETE_TASK_TYPE_CONFIG,
    UPDATE_TASK_TYPES,
    UPDATE_TASK_TYPE_CONFIG
} from "./actionTypes"

import { getTasksTypes, getTasksTypesFail, getTasksTypesSuccess,
         fetchParentTaskTypesSuccess,fetchParentTaskTypesFailure, 
         addTaskTypesFail, addTaskTypesSuccess,getTaskTypeConfigListSuccess,
         updateTaskTypesFail,updateTaskTypesSuccess,
         updateTaskTypeConfigFail,updateTaskTypeConfigSuccess,
         getTaskTypeConfigListFail,addTaskTypeConfigSuccess,addTaskTypeConfigFail,getTaskTypeConfigList,
         deleteTaskTypesFail,deleteTaskTypesSuccess,deleteTaskTypeFieldSuccess,deleteTaskTypeFieldFail} from "./actions"

import { getTaskTypesList,getParentTaskTypes,addNewTaskType,fetchTaskTypeConfigListApi,
         onAddNewConfigItemApi,deletetasktypeApi,deletetasktypefieldApi,
         updateTaskTypeAPI,onUpdateConfigItemApi } from "helpers/fakebackend_helper";


function* fetchTaskTypes() {
  try {
    const response = yield call(getTaskTypesList);
    if (!response.IsError) {
      yield put(getTasksTypesSuccess(response.Data))
    }
    else {
      yield put(getTasksTypesFail(response.ErrorMessage))
    }
  } catch (error) {
    yield put(getTasksTypesFail(error))
  }
}

function* fetchParentTaskTypes(){
  try {
    const response = yield call(getParentTaskTypes);
    if (!response.IsError) {
      yield put(fetchParentTaskTypesSuccess(response.Data))
    }
    else {
      yield put(fetchParentTaskTypesFailure(response.ErrorMessage))
    }
  } catch (error) {
    yield put(fetchParentTaskTypesFailure(error))
  }
}

function* onAddNewTaskType({ payload: tasktype }) {debugger;
  try {
    const response = yield call(addNewTaskType, tasktype);
    if (response.IsError) {
      yield put(addTaskTypesFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(addTaskTypesSuccess(response.Data));
      toast.success("Task Type Added Successfully", { autoClose: 2000 }); 
      yield put(getTasksTypes());
    }
  } catch (error) {
    yield put(addTaskTypesFail(error));
    toast.error("Task Type Added Failed", { autoClose: 2000 });
  }
}

function* onUpdateTaskType({ payload: tasktype }) {debugger;
  try {
    const response = yield call(updateTaskTypeAPI, tasktype);
    if (response.IsError) {
      yield put(updateTaskTypesFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(updateTaskTypesSuccess(response.Data));
      toast.success("Task Type Update Successfully", { autoClose: 2000 }); 
      yield put(getTasksTypes());
    }
  } catch (error) {
    yield put(updateTaskTypesFail(error));
    toast.error("Task Type Added Failed", { autoClose: 2000 });
  }
}

function* fetchTaskTypeConfigList({ payload: taskTypeId }) {debugger;
  try {
    const response = yield call(fetchTaskTypeConfigListApi, taskTypeId);
    if (!response.IsError) {
      yield put(getTaskTypeConfigListSuccess(response.Data));
    } else {
      yield put(getTaskTypeConfigListFail(response.ErrorMessage));
    }
  } catch (error) {
    yield put(getTaskTypeConfigListFail(error));
  }
}

function* onAddNewConfigItem({ payload: configitem }) {debugger;
  try {
    const response = yield call(onAddNewConfigItemApi, configitem);
    if (response.IsError) {
      yield put(addTaskTypeConfigFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(addTaskTypeConfigSuccess(response.Data));
      toast.success("Configuration Added Successfully", { autoClose: 2000 }); 
      yield put(getTaskTypeConfigList(configitem.task_type_id));
    }
  } catch (error) {
    yield put(addTaskTypeConfigFail(error));
    toast.error("Configuration Added Failed", { autoClose: 2000 });
  }
}

function* onUpdateConfigItem({ payload: configitem }) {
  try {
    const response = yield call(onUpdateConfigItemApi, configitem);
    if (response.IsError) {
      yield put(updateTaskTypeConfigFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(updateTaskTypeConfigSuccess(response.Data));
      toast.success("Configuration Updated Successfully", { autoClose: 2000 }); 
      yield put(getTaskTypeConfigList(configitem.task_type_id));
    }
  } catch (error) {
    yield put(updateTaskTypeConfigFail(error));
    toast.error("Configuration Added Failed", { autoClose: 2000 });
  }
}

function* DeleteTaskType({ payload: { task_type_id } }) {debugger;
  try {
    const response = yield call(deletetasktypeApi, task_type_id );
    if(response.IsError){
      yield put(deleteTaskTypesFail(response.ErrorMessage))
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }
    else{
      yield put(deleteTaskTypesSuccess(task_type_id))
      toast.success(response.SuccessMessage, { autoClose: 2000 });
    }
    
  } catch (error) {
    yield put(deleteTaskTypesFail(error))
    toast.error("TaskType Deleted Failed", { autoClose: 2000 });
  }
}

function* DeleteTaskTypeField({ payload: { field_id } }) {debugger;
  try {
    const response = yield call(deletetasktypefieldApi, field_id );
    if(response.IsError){
      yield put(deleteTaskTypeFieldFail(response.ErrorMessage))
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }
    else{
      yield put(deleteTaskTypeFieldSuccess(field_id))
      toast.success(response.SuccessMessage, { autoClose: 2000 });
      
    }
    
  } catch (error) {
    yield put(deleteTaskTypeFieldFail(error))
    toast.error("TaskType Deleted Failed", { autoClose: 2000 });
  }
}

function* tasktypesSaga() {
  yield takeEvery(GET_TASKS_TYPES, fetchTaskTypes);
  yield takeEvery(FETCH_PARENT_TASKTYPES, fetchParentTaskTypes);
  yield takeEvery(ADD_NEW_TASK_TYPES,onAddNewTaskType);
  yield takeEvery(UPDATE_TASK_TYPES,onUpdateTaskType);
  yield takeEvery(GET_TASKTYPE_CONFIG_LIST, fetchTaskTypeConfigList);
  yield takeEvery(ADD_TASK_TYPE_CONFIG,onAddNewConfigItem);
  yield takeEvery(UPDATE_TASK_TYPE_CONFIG,onUpdateConfigItem);
  yield takeEvery(DELETE_TASK_TYPES,DeleteTaskType);
  yield takeEvery(DELETE_TASK_TYPE_CONFIG, DeleteTaskTypeField);
  
}

export default tasktypesSaga
