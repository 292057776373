import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import fetch_pdf_file from "../../helpers/fileservice";
import CryptoJS from 'crypto-js';
import FileViewer from 'react-file-viewer';
import { Card, CardBody, Container, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
const secretKey = process.env.REACT_APP_SECRETKEY;

const DocumentFileViewerPage = () => {
    const [docBlobUrl, setdocBlobUrl] = useState(null);
    //const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileName, setFileName] = useState(null);
    //const defaultLayoutPluginInstance = defaultLayoutPlugin();

    // Get bucket and object key from URL query parameters
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('data');

    const decryptQueryString = (encryptedData) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
            const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decrypted;
        } catch (error) {
            throw new Error('Failed to decrypt data');
        }
    };


    useEffect(() => {
        let blobUrl = null;  // Initialize blobUrl to null
        if (encryptedData) {
            debugger;
            try {
                // Decrypt the query parameters
                const { bucket, objectKey } = decryptQueryString(encryptedData);

                const fetchdocument = async () => {
                    try {
                        console.log("Bucket Name:", bucket);
                        console.log("Object Key:", objectKey);

                        // Extract file name and file extension (type)
                        const fileName = objectKey.split('/').pop(); // Get the file name
                        const fileType = fileName.split('.').pop().toLowerCase(); // Get the file extension

                        setFileName(fileName); // Set file name in state
                        setFileType(fileType); // Set file type in state

                        // Fetch the PDF using bucket and objectKey
                        const response = await fetch_pdf_file(bucket, objectKey);
                        blobUrl = URL.createObjectURL(response);  // Create Blob URL for the PDF
                        setdocBlobUrl(blobUrl);  // Update state with Blob URL
                    } catch (error) {
                        console.error('Error fetching PDF:', error);
                        setError('Failed to load PDF');
                    }
                };

                // Call the asynchronous function to fetch PDF
                fetchdocument();
            } catch (err) {
                console.error('Decryption error:', err);
                setError('Invalid or corrupted data');
            }
        } else {
            setError('No data provided');
        }

        // Cleanup function to revoke the Blob URL when the component unmounts or URL changes
        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);  // Clean up Blob URL
            }
        };
    }, [encryptedData]);


    const selectedViewer = {
        name: 'google',  // Using Google Docs Viewer
        viewerUrl: 'https://docs.google.com/viewer',
    };
     // Function to handle file download
     const handleDownload = () => {
        const link = document.createElement('a');
        link.href = docBlobUrl;
        link.download = fileName || 'downloaded_file'; // Use the file name from the file object or fallback to a default name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element after download
    };
    
    return (        
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="File List" breadcrumbItem="File Detail" link="/fileslist" />
                    <Card>
                    <CardBody className="border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1">{fileName}</h5>
                                <div className="flex-shrink-0">
                                    <Button color="primary" onClick={handleDownload} className="btn me-1 mt-2">
                                        Download
                                    </Button>                                   
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                            {docBlobUrl ? (
                                <FileViewer
                                    key={fileName} // Add this key prop to force re-render on file change
                                    fileType={fileType}
                                    filePath={docBlobUrl} // Use the correct file URL fetched from the API
                                    onError={(e) => console.error("Error viewing file:", e)}
                                />
                            ) : (
                                <p>Loading document...</p>
                            )}

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DocumentFileViewerPage;
