import React, { useEffect, useState, useMemo } from "react";
import { Link,useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  UncontrolledTooltip 
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import {
  getTasksTypes as onGetTasks,
  addNewTaskTypes as onAddNewTaskType,
  updateTaskTypes as onUpdateTask,
  deleteTaskTypes as onDeleteTask,
} from "store/tasktypes/actions";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

const TaskTypesList = () => {
  document.title = "Task Types List";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tasktype, setTasktype] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      task_type_name: tasktype?.task_type_name || "",
      description: tasktype?.description || "",
      parent_id: tasktype?.parent_id !== undefined ? tasktype?.parent_id : null,
    },
    validationSchema: Yup.object({
      task_type_name: Yup.string().required("Please Enter task type"),
      description: Yup.string().required("Please Enter task description"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateTask = {
          task_type_id: tasktype.task_type_id,
          task_type_name: values.task_type_name,
          description: values.description,
          parent_id: values.parent_id,
        };
        dispatch(onUpdateTask(updateTask));
      } else {
        const newTaskType = {
          task_type_name: values.task_type_name,
          description: values.description,
          parent_id: values.parent_id,
        };
        dispatch(onAddNewTaskType(newTaskType));
      }
      validation.resetForm();
      toggle();
    },
  });

  const TaskTypeProperties = createSelector(
    (state) => state.tasktypes,
    (TaskTypes) => ({
      tasktypes: TaskTypes.tasktypes,
      loading: TaskTypes.loading,
    })
  );

  const { tasktypes, loading } = useSelector(TaskTypeProperties);

  useEffect(() => {
    if (!tasktypes.length) {
      dispatch(onGetTasks());
    }
  }, [dispatch, tasktypes.length]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleTasksClicks = () => {
    setTasktype(null);
    setIsEdit(false);
    toggle();
  };

  const TaskTypeEditClick = (taskData) => {
    setTasktype({
      task_type_id: taskData.task_type_id ?? "",
      task_type_name: taskData.task_type_name ?? "",
      description: taskData.task_description ?? "",
      parent_id: taskData.parent_id !== undefined ? taskData.parent_id : null,
    });
    setIsEdit(true);
    toggle();
  };

  const handleParentChange = (e) => {
    const selectedParent = e.target.value;
    validation.setFieldValue("parent_id", selectedParent);
  };

  const onClickDelete = (taskTypeData) => {
    setSelectedTaskType(taskTypeData);
    setDeleteModal(true);
  };

  const handleDeleteTask = () => {
    if (selectedTaskType?.task_type_id) {
      dispatch(onDeleteTask(selectedTaskType.task_type_id));
    }
    setDeleteModal(false);
  };

  const parentTaskTypes = useMemo(() => {
    if (tasktype && tasktype.task_type_id) {
      // Edit mode: filter out the current task_type_id
      return tasktypes.filter(
        (task) => task.isparent === true && task.task_type_id !== tasktype.task_type_id
      );
    } else {
      // New mode: no need to filter out the current task_type_id
      return tasktypes.filter((task) => task.isparent === true);
    }
  }, [tasktypes, tasktype]);
  
  const viewTaskTypeFields = (taskData) => {
    navigate("/tasks-create", {
      state: { task_type_id: taskData.task_type_id, task_type_name: taskData.task_type_name },
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "img",
        cell: (cell) => (
          <>
            {!cell.getValue() ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cell.row.original.task_type_name.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={cell.getValue()} alt="" />
              </div>
            )}
          </>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Task Type Name",
        accessorKey: "task_type_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "task_description",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Parent",
        accessorKey: "parent_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={(e) => {
                e.preventDefault();
                const taskTypeData = cellProps.row.original;
                TaskTypeEditClick(taskTypeData);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <UncontrolledTooltip placement="top" target="edittooltip">
            Edit Task Type
           </UncontrolledTooltip>
            <Link
            to="#"
            className="text-success"
            onClick={(e) => {
              e.preventDefault();
              const taskTypeData = cellProps.row.original;
              viewTaskTypeFields(taskTypeData);
            }} >
            <i className="mdi mdi-eye font-size-18" id="viewFields" />
            </Link>
            <UncontrolledTooltip placement="top" target="viewFields">
            View Task Type Fields
           </UncontrolledTooltip>
            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                const taskTypeData = cellProps.row.original;
                onClickDelete(taskTypeData);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
            <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete Task Type
           </UncontrolledTooltip>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
        text="Are you sure you want to delete this task type and related fields?"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="TaskType" breadcrumbItem="TaskTypes List" />
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={tasktypes || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      SearchPlaceholder="Search..."
                      isCustomPageSize={true}
                      isAddButton={true}
                      handleUserClick={handleTasksClicks}
                      buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                      buttonName="New Task"
                      tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      pagination="pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {isEdit ? "Edit Task Type" : "Add Task Type"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="tasktype-input">Task Type Name</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          name="task_type_name"
                          type="text"
                          placeholder="Enter Task Type Name..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.task_type_name || ""}
                          invalid={
                            validation.touched.task_type_name && validation.errors.task_type_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.task_type_name && validation.errors.task_type_name ? (
                          <FormFeedback type="invalid">{validation.errors.task_type_name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Task Description</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          name="description"
                          placeholder="Enter Description"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description && validation.errors.description
                              ? true
                              : false
                          }
                        />
                        {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="parent-input">Parent Task</Label>
                        <select
                          className={`form-select pageSize ${
                            validation.touched.parent_id && validation.errors.parent_id ? "is-invalid" : ""
                          }`}
                          id="parent"
                          name="parent_id"
                          onChange={handleParentChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.parent_id || ""}
                        >
                          <option value="">Select a Parent Task</option>
                          {parentTaskTypes.map((task) => (
                            <option key={task.task_type_id} value={task.task_type_id}>
                              {task.task_type_name}
                            </option>
                          ))}
                        </select>
                        {validation.touched.parent_id && validation.errors.parent_id && (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.parent_id}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success save-user">
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(TaskTypesList);
