import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Alert,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/defaultprofile.png";
import { getLoggedInUser } from "../../helpers/fakebackend_helper";

const UserProfile = () => {
  // Set the document title
  useEffect(() => {
    document.title = "Profile | DataOS";
  }, []);

  const dispatch = useDispatch();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getLoggedInUser();
        setLoggedInUser(user);
        console.log("Logged In User:", user); // Check loggedInUser data here
      } catch (error) {
        console.error("Error fetching logged in user", error);
        setError("Failed to load user data.");
      }
    };
    fetchUser();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="DataOS" breadcrumbItem="Profile" />

          {error && <Alert color="danger">{error}</Alert>}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        {loggedInUser ? (
                          <>
                            <h5>{loggedInUser.first_name}</h5>
                            <p className="mb-1">{loggedInUser.email}</p>
                            <p className="mb-0">
                              Company: #{loggedInUser.company_name}
                            </p>
                          </>
                        ) : (
                          <p>Loading user data...</p>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <CardTitle className="mb-4">Personal Information</CardTitle>
              <div className="table-responsive">
                <Table className="table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">First Name :</th>
                      <td>{loggedInUser ? loggedInUser.first_name : "N/A"}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Name :</th>
                      <td>{loggedInUser ? loggedInUser.last_name : "N/A"}</td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile :</th>
                      <td>{loggedInUser ? loggedInUser.phonenumber : "N/A"}</td>
                    </tr>
                    <tr>
                      <th scope="row">E-mail :</th>
                      <td>{loggedInUser ? loggedInUser.email : "N/A"}</td>
                    </tr>
                    <tr>
                      <th scope="row">User Type:</th>
                      <td>{loggedInUser ? loggedInUser.user_type : "N/A"}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
