import React from 'react';
import { Handle, Position } from 'reactflow';
//import S3 from '../../../assets/images/S3.png'
import nodeicon from '../../../assets/images/nodeicon.png'

const CustomNode = ({ id, data, onDelete, isConnectable, onDuplicate}) => {
    return (
        <>
            <button className="btn btn-danger DnDbtn"
                onClick={(e) => {
                    e.stopPropagation(); // Stop the event from bubbling up
                    const task_id = data.task_id;
                    onDelete(id, task_id);
                    console.log(id,data.task_id);
                }}
            >
                <i className="mdi mdi-delete font-size-12" /></button>
                <button className="btn btn-success Dupbtn"
                onClick={(e) => {
                    e.stopPropagation(); // Stop the event from bubbling up
                    onDuplicate(id);
                }}
            ><i className="mdi mdi-content-copy font-size-12" /></button>
            <div className="customNode">
                <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={isConnectable}
                />
                <div className="customNode-content">
                    <img src={nodeicon} className="customNode-image" alt="S3" />
                    <div className="customNode-label">{data.label}</div>                    
                    {!data.isvalid ? <span className="text-danger"><i className="mdi mdi-alert-circle-outline font-size-12" /></span> : ''}
                </div>
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                />               
            </div>
        </>
    )
}

export default CustomNode;