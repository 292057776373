import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"; 
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { changePasswordRequest } from "../../store/actions";
import avatar from "../../assets/images/users/defaultprofile.png";
import { getLoggedInUser } from "../../helpers/fakebackend_helper";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const ChangePasswordScreen = () => {
  document.title = "Change Password";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success } = useSelector(state => state.passwordChangeReducer);
  const [loggedInUser, setLoggedInUser] = useState({});

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Please enter your new password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required("Please confirm your new password"),
    }),
    onSubmit: (values) => {
      dispatch(changePasswordRequest({ user_id: loggedInUser.user_id, old_password:null,new_password:values.confirmPassword }));
    },
  });

  useEffect(() => {
    if (success) {
      // Logout and redirect to login page after a short delay to allow the toast to be visible
      setTimeout(() => {
        localStorage.removeItem("authUser");
        navigate("/login");
      }, 2000); // 2-second delay
    }
  }, [success, navigate]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getLoggedInUser();
        setLoggedInUser(user);
      } catch (error) {
        console.error("Error fetching logged in user", error);
      }
    };
    fetchUser();
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Change Password</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="user-thumb text-center mb-4">
                        <img
                          src={avatar}
                          className="rounded-circle img-thumbnail avatar-md"
                          alt="thumbnail"
                        />
                        <h5 className="font-size-15 mt-3">{loggedInUser?.first_name} {loggedInUser?.last_name}</h5>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="newPassword"
                          className="form-control"
                          placeholder="Enter new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.newPassword || ""}
                          invalid={
                            validation.touched.newPassword && validation.errors.newPassword
                          }
                        />
                        {validation.touched.newPassword && validation.errors.newPassword ? (
                          <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm new password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword && validation.errors.confirmPassword
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>

                      {error && <div className="alert alert-danger">{error}</div>}
                      {success && <div className="alert alert-success">Password changed successfully</div>}

                      <div className="text-end">
                        <Col xs="12" className="text-end">
                          <Button
                            color="primary"
                            className="w-md"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Updating..." : "Update"}
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ChangePasswordScreen;
