import React from "react";
import withRouter from "components/Common/withRouter";
import { Container } from  "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DnDFlow from "../DnD/DnDFlow";
import { useParams, useLocation } from "react-router-dom";



const WorkflowBuilder = () => {
    // document.title = "Workflow Builder";
    // const { id } = useParams();
    // const isEditMode = !!id;
    // document.title = isEditMode ? "Edit Workflow" : "Create Workflow";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const config_id = searchParams.get("config_id");
    const isEditMode = !!config_id;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Workflows" breadcrumbItem="Workflow Builder" />                    */}
                    {/* <Breadcrumbs 
                        title="Workflows" 
                        breadcrumbItem={isEditMode ? "Edit Workflow" : "Create Workflow"} 
                    /> */}
                    <DnDFlow isEditMode={isEditMode} workflowId={config_id}/>                    
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(WorkflowBuilder);