import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR
} from "./actionTypes"

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  userId: null,
  otpError: null,
  otpSuccess: false
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: 'OTP sent to your email.',
        userId: action.payload.userId,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload, forgetSuccessMsg: null, }
      break
    case VERIFY_OTP_SUCCESS:
      state = {
        ...state,
        otpError: null,
        otpSuccess: true,
      }
      break
    case VERIFY_OTP_ERROR:
      state = {
        ...state,
        otpError: action.payload,
        otpSuccess: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
