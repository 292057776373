import PropTypes from "prop-types";
import React from "react";
import {
    Container
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";


const ModulePage = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Modules")}
                        breadcrumbItem={props.t("Module Page")}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
};
ModulePage.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ModulePage);

