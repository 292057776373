import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERS, GET_USER_PROFILE, ADD_NEW_USER, DELETE_USER, UPDATE_USER } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getUsers, getUserProfile, addNewUser, updateUser, deleteUser } from "../../helpers/fakebackend_helper"

// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function* fetchUsers() {
  try {
    const response = yield call(getUsers);
    console.log('respone:',response);
    if (!response.IsError) {
      console.log('Users data from db:',response.Data);
      yield put(getUsersSuccess(response.Data))
    } else {
      yield put(getUsersFail(response.ErrorMessage))
    }
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user);
    if(!response.IsError){
      yield put(updateUserSuccess(user))
      toast.success("Contact Updated Successfully", { autoClose: 2000 });
    }
    else{
      yield put(updateUserFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(updateUserFail(error))
    toast.error("Contact Updated Failed", { autoClose: 2000 });
  }
}


  function* onDeleteUser({ payload: { company_id, user_id } }) {debugger;
  try {
    const response = yield call(deleteUser, { company_id, user_id });
    console.log(response);
    if(response.IsError){
      yield put(deleteUserFail(response.ErrorMessage))
      toast.error("Contact Deleted Failed", { autoClose: 2000 });
    }
    else{
      yield put(deleteUserSuccess(user_id))
      toast.success("Contact Deleted Successfully", { autoClose: 2000 });
    }
    
  } catch (error) {
    yield put(deleteUserFail(error))
    toast.error("Contact Deleted Failed", { autoClose: 2000 });
  }
}

function* onAddNewUser({ payload: user }) {debugger;
  try {
    const response = yield call(addNewUser, user);
    if (response.IsError) {
      yield put(addUserFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(addUserSuccess(user));
      toast.success("Contact Added Successfully", { autoClose: 2000 }); 
    }
  } catch (error) {
    yield put(addUserFail(error));
    toast.error("Contact Added Failed", { autoClose: 2000 });
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;
