import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import InputMask from 'react-input-mask';
import { ToastContainer } from "react-toastify";
const Register = props => {

  //meta title
  document.title = "Register";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: '',
      company_website: '',
      first_name: '',
      last_name: '',
      email: '',
      phonenumber: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Please Enter Your Company Name"),
      company_website: Yup.string().required("Please Enter Your Company Website"),
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().required("Please Enter Your Email"),
      phonenumber: Yup.string().required("Please Enter Phone Number").matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid'),
      password: Yup.string().required("Please Enter Your Password"),
      confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Please Confirm Your Password')
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });


  const AccountProperties = createSelector(
    (state) => state.account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success
      // loading: account.loading,
    })
  );

  const {
    user,
    registrationError, success
    // loading
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 4000)
  }, [success])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={7}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free DataOS account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Company account created successfully! Please check your email to verify your account.
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Company Name</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="company_name"
                              name="company_name"
                              className="form-control"
                              placeholder="Enter company name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.company_name || ""}
                              invalid={validation.touched.company_name && validation.errors.company_name ? true : false}
                            />
                            {validation.touched.company_name && validation.errors.company_name ? (
                              <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Company Website</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="company_website"
                              name="company_website"
                              className="form-control"
                              placeholder="Enter company website"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.company_website || ""}
                              invalid={validation.touched.company_website && validation.errors.company_website ? true : false}
                            />
                            {validation.touched.company_website && validation.errors.company_website ? (
                              <FormFeedback type="invalid">{validation.errors.company_website}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="first_name"
                              name="first_name"
                              className="form-control"
                              placeholder="Enter first name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                            />
                            {validation.touched.first_name && validation.errors.first_name ? (
                              <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="last_name"
                              name="last_name"
                              className="form-control"
                              placeholder="Enter last name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={validation.touched.last_name && validation.errors.last_name ? true : false}
                            />
                            {validation.touched.last_name && validation.errors.last_name ? (
                              <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={validation.touched.email && validation.errors.email ? true : false}
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <span style={{ color: "red" }}>*</span>
                            <InputMask
                              mask="(999) 999-9999"
                              value={validation.values.phonenumber}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                            >
                              {(inputProps) => (
                                <Input
                                  {...inputProps}
                                  name="phonenumber"
                                  type="text"
                                  placeholder="Insert Phone Number"
                                  invalid={
                                    validation.touched.phonenumber &&
                                      validation.errors.phonenumber
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            </InputMask>
                            {validation.touched.phonenumber && validation.errors.phonenumber ? (
                              <FormFeedback type="invalid">{validation.errors.phonenumber}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="password"
                              name="password"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Confirm Password</Label>
                            <span style={{ color: "red" }}>*</span>
                            <Input
                              id="confirm_password"
                              name="confirm_password"
                              className="form-control"
                              placeholder="Confirm password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ""}
                              invalid={validation.touched.confirm_password && validation.errors.confirm_password ? true : false}
                            />
                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-4">
                        <button className="btn btn-primary btn-block" type="submit">
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the DataOS{" "}
                          <Link to="#" className="text-primary">Terms of Use</Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "} <Link to="/login" className="font-weight-medium text-primary"> Login </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} DataOS. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by DataOS
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Register;
