// saga.js
import { takeEvery, call, put } from "redux-saga/effects";
import {
    CHANGE_PASSWORD_REQUEST,
  } from "./actionTypes";
import {
  changePasswordSuccess,
  changePasswordFailure,
} from "./actions";
import { changePasswordAPI } from "../../../helpers/fakebackend_helper";
import { toast } from "react-toastify";

function* handleChangePassword({ payload: data }) {debugger;
    try {
      const response = yield call(changePasswordAPI,data);
      if (response.IsError) {
        yield put(changePasswordFailure(response.ErrorMessage));
        toast.error(response.ErrorMessage, { autoClose: 2000 });
      } else {
        yield put(changePasswordSuccess());
        toast.success("Password Changes Successfully", { autoClose: 2000 }); 
      }
    } catch (error) {
      yield put(changePasswordFailure(error));
      toast.error("Password Changes Failed", { autoClose: 2000 });
    }
  }


function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, handleChangePassword);
}

export default changePasswordSaga;
