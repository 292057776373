import {
    GET_FILES_SUCCESS,
    GET_FILES_FAIL,
    VIEW_FILE_SUCCESS,
    VIEW_FILE_FAIL,
    GET_FILTERED_FILES_SUCCESS,
    GET_FILTERED_FILES_FAIL,
    GET_CUSTOM_FILTERS_LIST_SUCCESS,
    GET_CUSTOM_FILTERS_LIST_FAIL,
    ADD_CUSTOM_FILTER_SUCCESS,
    ADD_CUSTOM_FILTER_FAIL,
    UPDATE_CUSTOM_FILTER_SUCCESS,
    UPDATE_CUSTOM_FILTER_FAIL,
    DELETE_CUSTOM_FILTER_SUCCESS,
    DELETE_CUSTOM_FILTER_FAIL,
    GET_CUSTOM_FILTER_META_DATA_SUCCESS,
    GET_CUSTOM_FILTER_META_DATA_FAIL,
    UPDATE_CUSTOM_FILTER_META_DATA_SUCCESS,
    UPDATE_CUSTOM_FILTER_META_DATA_FAIL
} from "./actionTypes";

const INIT_STATE = {
    files: [],
    error: {},
    loading: false,
    //fileUrl: null,
    fileBlob: null,
    customFiltersList: [],
    metadata: [],
}

const filemanager = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FILES_SUCCESS:
            return {
                ...state,
                files: action.payload.Data,
                loading: true
            };

        case GET_FILES_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case VIEW_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                //fileUrl: action.payload, 
                fileBlob: action.payload,
            };
        case VIEW_FILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case GET_FILTERED_FILES_SUCCESS:
            return {
                ...state,
                files: action.payload.Data,
                loading: true
            };

        case GET_FILTERED_FILES_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case GET_CUSTOM_FILTERS_LIST_SUCCESS:
            return {
                ...state,
                customFiltersList: action.payload,
                loading: true
            };
        case GET_CUSTOM_FILTERS_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            };
        // Add custom filter success
        case ADD_CUSTOM_FILTER_SUCCESS:
            return {
                ...state,
                customFiltersList: [...state.customFiltersList, action.payload], // Add new filter
                loading: false
            };

        case ADD_CUSTOM_FILTER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };


        case UPDATE_CUSTOM_FILTER_SUCCESS:
            return {
                ...state,
                customFiltersList: state.customFiltersList.map((filter) =>
                    filter.attribute_id === action.payload.attribute_id
                        ? { ...filter, ...action.payload }
                        : filter
                ),
                loading: false
            };

        case UPDATE_CUSTOM_FILTER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        // Delete custom filter success
        case DELETE_CUSTOM_FILTER_SUCCESS:
            return {
                ...state,
                customFiltersList: state.customFiltersList.filter(filter => filter.id !== action.payload.id), // Remove the matching filter
                loading: false
            };

        case DELETE_CUSTOM_FILTER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_CUSTOM_FILTER_META_DATA_SUCCESS:
            return {
                ...state,
                metadata: action.payload,
                loading: false
            };
        case GET_CUSTOM_FILTER_META_DATA_FAIL:
            return {
                ...state,
                error: action.payload
            };
        // Update custom filter success
        case UPDATE_CUSTOM_FILTER_META_DATA_SUCCESS:
            return {
                ...state,
                metadata: [...state.metadata, action.payload], // Add new filter
                loading: false
            };

        case UPDATE_CUSTOM_FILTER_META_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }

}

export default filemanager;
