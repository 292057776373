// saga.js
import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_DASHBOARD_DATA,
} from "./actionTypes";
import { fetchDashboardDataSuccess, fetchDashboardDataFailure } from "./actions";
import { fetchDashboardDataApi } from "../../helpers/fakebackend_helper"


function* fetchDashboardDataSaga({ payload: data }) {
  try {
    const response = yield call(fetchDashboardDataApi,data);
    console.log(response);
    if (!response.IsError){
        yield put(fetchDashboardDataSuccess(response.Data));
        console.log(response.Data);
    }
    else{
        yield put(fetchDashboardDataFailure(response.ErrorMessage));
    }
  } catch (error) {
    yield put(fetchDashboardDataFailure(error));
  }
}

function* dashboardSaga() {
  yield takeEvery(FETCH_DASHBOARD_DATA, fetchDashboardDataSaga);
}

export default dashboardSaga;
