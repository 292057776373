import { call, put, takeEvery } from "redux-saga/effects"
import {
    UPDATE_WORKFLOW_TASK_CONFIG,
    GET_WORKFLOW_TASK_CONFIG
} from "./actionTypes"
import {
    UpdateWorkflowTaskConfigSuccess,
    UpdateWorkflowTaskConfigFail,
    GetWorkflowTaskConfigListSuccess,
    GetWorkflowTaskConfigListFail
} from "./actions"
import { updateWfTaskConfigApi,getWorkflowTaskConfigApi} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* onUpdateWorkflowTaskConfig({ payload: taskconfigdata }) {debugger;
    try {
      const response = yield call(updateWfTaskConfigApi, taskconfigdata);
      console.log(response);
      if (response.IsError) {
        yield put(UpdateWorkflowTaskConfigFail(response.ErrorMessage));
        toast.error(response.ErrorMessage, { autoClose: 2000 });
      } else {
        yield put(UpdateWorkflowTaskConfigSuccess(response.Data));
        toast.success(response.SuccessMessage, { autoClose: 2000 }); 
      }
    } catch (error) {
      yield put(UpdateWorkflowTaskConfigFail(error));
      toast.error("Task Configuration Added Failed", { autoClose: 2000 });
    }
  }

  function* onFetchWorkflowTaskConfig({ payload: workflow_id,task_id,tasktype_id }) {
    try {
      const response = yield call(getWorkflowTaskConfigApi, workflow_id,task_id,tasktype_id);
      if (response.IsError) {
        yield put(GetWorkflowTaskConfigListFail(response.ErrorMessage));
        toast.error(response.ErrorMessage, { autoClose: 2000 });
      } else {
        yield put(GetWorkflowTaskConfigListSuccess(response.Data));
      }
    } catch (error) {
      yield put(GetWorkflowTaskConfigListFail(error));
      toast.error("Fetching Task Configuration Failed", { autoClose: 2000 });
    }
  }

function* wf_taskConfigurationSaga() {
    yield takeEvery(UPDATE_WORKFLOW_TASK_CONFIG,onUpdateWorkflowTaskConfig)
    yield takeEvery(GET_WORKFLOW_TASK_CONFIG,onFetchWorkflowTaskConfig)
  }
export default wf_taskConfigurationSaga