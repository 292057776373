import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
  } from "./actionTypes";
  
  const initialState = {
    loading: false,
    error: null,
    success: false,
  };
  
  const passwordChangeReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
        };
      case CHANGE_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default passwordChangeReducer;
  