// import React from "react";
// import { Navigate } from "react-router-dom";

// const Authmiddleware = (props) => {

//   if (!localStorage.getItem("authUser")) {
//     return (
//       <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
//     );
//   }
//   return (<React.Fragment>
//     {props.children}
//   </React.Fragment>);
// };

// export default Authmiddleware;

// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";
// import { useOktaAuth } from '@okta/okta-react'; // Import Okta's authentication hook
// import { useDispatch, useSelector } from 'react-redux'; // Redux hooks
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { validateOktaTokenRequest } from '../store/auth/login/actions';

// const Authmiddleware = (props) => {
//   const { authState, oktaAuth } = useOktaAuth(); // Get authState and Okta's oktaAuth object
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { user, loading } = useSelector(state => state.Login); // Access auth state from Redux

//   useEffect(() => {
//     if (authState && authState.isAuthenticated) {
//       console.log(authState);
//       setIsAuthenticated(true);
//       const email = authState.idToken?.claims.email;
//       const oktaId = authState.idToken?.claims.sub;

//       // Dispatch action to validate token and fetch profile
//       dispatch(validateOktaTokenRequest({ email, oktaId }));
//        // User is authenticated
//     }
//   }, [authState, dispatch]);

//   useEffect(() => {
//     console.log('User:', user); // Log user state
//     console.log('Loading:', loading); // Log loading state
//     // Redirect when user profile is loaded
//     if (user && !loading) {
//       navigate('/dashboard'); // Navigate after successful validation
//     }
//   }, [user, loading, navigate]);

//   // useEffect(() => {
//   //   if (authState) {
//   //     if (authState.isAuthenticated) {
//   //       console.log('authState:', authState);
//   //       // If authenticated via Okta, save token in localStorage (or handle it as per your app's logic)
//   //       localStorage.setItem("authUser", JSON.stringify(authState.idToken)); // Save Okta token if needed
//   //       setIsAuthenticated(true);
//   //     } else {
//   //       localStorage.removeItem("authUser"); // Remove if not authenticated
//   //     }
//   //   }
//   // }, [authState]);

//   // Fallback to Okta loading state
  

//   // Check both local authUser and Okta authState
//   if (!localStorage.getItem("authUser") || !isAuthenticated) {
//     return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
//   }

//   if (!authState.isAuthenticated || (!user && !loading)) {
//     return (
//       <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
//     );
//   }

//   return (<React.Fragment>{props.children}</React.Fragment>);
// };

// export default Authmiddleware;

import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from '@okta/okta-react';
import { validateOktaTokenRequest } from "../store/auth/login/actions"; // Adjust the import based on your file structure

const Authmiddleware = (props) => {
  const { user, isAuthenticated } = useSelector((state) => state.Login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));

    // If user is found in localStorage, navigate to dashboard
    if (authUser) {
      // If user is authenticated, navigate to the dashboard
      if (isAuthenticated) {
        navigate("/dashboard");
      }
      return; // Exit the effect
    }

    // If no user in localStorage, check Okta authState
    if (!authUser && !isAuthenticated) {
      
      if(authState && authState.isAuthenticated) {
        console.log(authState);
        const email = authState.idToken?.claims.email;
        const oktaId = authState.idToken?.claims.sub;
        //const { email, oktaId } = authState.idToken.claims; // Extract email and oktaId
        console.log(email);
        console.log(oktaId);
        dispatch(validateOktaTokenRequest({ email, oktaId }));
        navigate("/dashboard");
      } else {
        // If not authenticated in both localStorage and Okta, navigate to login
        navigate("/login");
      }
    }
  }, [dispatch, isAuthenticated, navigate]);

  // If authUser is not found in localStorage and user is not authenticated, navigate to login
  if (!localStorage.getItem("authUser") && !isAuthenticated) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;


