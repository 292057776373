import React, { useState } from "react";
import withRouter from "components/Common/withRouter";
import { Container, Row, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

const CustomTableBuilder = () => {
    const [columns, setColumns] = useState(['Column1']);
    const [rows, setRows] = useState([['']]);
    // Add a new column
  const addColumn = () => {
    const newColumnName = `Column ${columns.length + 1}`;
    setColumns([...columns, newColumnName]);

    // Add an empty cell to each row for the new column
    const updatedRows = rows.map((row) => [...row, '']);
    setRows(updatedRows);
  };

  // Add a new row
  const addRow = () => {
    const newRow = Array(columns.length).fill(''); // Create an empty row
    setRows([...rows, newRow]);
  };

  // Handle cell data change
  const handleCellChange = (rowIndex, colIndex, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][colIndex] = value;
    setRows(updatedRows);
  };

  // Handle column name change
  const handleColumnNameChange = (colIndex, value) => {
    const updatedColumns = [...columns];
    updatedColumns[colIndex] = value;
    setColumns(updatedColumns);
};

// Delete a row
const deleteRow = (rowIndex) => {
    const updatedRows = rows.filter((_, index) => index !== rowIndex);
    setRows(updatedRows);
};

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Custom Table" breadcrumbItem="Custom Table Builder" />
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <table className="table">
                                    <thead>
                                            <tr>
                                                {columns.map((colName, index) => (
                                                    <th key={index}>
                                                        <input
                                                            type="text"
                                                            value={colName}
                                                            onChange={(e) => handleColumnNameChange(index, e.target.value)}
                                                        />
                                                    </th>
                                                ))}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {rows.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {row.map((cellData, colIndex) => (
                                                        <td key={colIndex}>
                                                            <input
                                                                type="text"
                                                                value={cellData}
                                                                onChange={(e) =>
                                                                    handleCellChange(rowIndex, colIndex, e.target.value)
                                                                }
                                                            />
                                                        </td>
                                                    ))}
                                                    <td>
                                                        <button
                                                            onClick={() => deleteRow(rowIndex)}
                                                            className="btn btn-danger"
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="hstack gap-2">
                                    <button onClick={addColumn} className="btn btn-soft-primary">Add Column</button>
                                    <button onClick={addRow} className="btn btn-soft-danger">Add Row</button>
                                </div>
                            </CardBody>
                        </Card>

                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(CustomTableBuilder);