import React, { useState, useEffect } from 'react';
import { getTasksTypes as onGetTasks } from "store/tasktypes/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

// Utility function to build the tree
const buildTree = (tasks) => {
  const taskMap = {};
  const roots = [];

  tasks.forEach(task => {
    task.children = [];
    taskMap[task.task_type_id] = task;
  });

  tasks.forEach(task => {
    if (task.parent_id !== null) {
      taskMap[task.parent_id].children.push(task);
    } else {
      roots.push(task);
    }
  });

  return roots;
};

// Recursive TreeNode component
const TreeNode = ({ node, onDragStart }) => {
  const [collapsed, setCollapsed] = useState(true);
  const hasChildren = node.children && node.children.length > 0;

  return (
    <li className= "py-2 d-block">
      <div
        className={`dndnode ${hasChildren ? '' : 'draggable'}`}
        onDragStart={!hasChildren ? (event) => onDragStart(event, 'customNode', node.task_type_name, node.task_type_id ) : undefined}
        draggable={!hasChildren}
        onClick={() => hasChildren && setCollapsed(!collapsed)}
        style={{ cursor: hasChildren ? 'pointer' : 'grab' }}
      >
        {hasChildren && (
          <span style={{ marginRight: 10 }}>
            {collapsed ? '▶' : '▼'}
          </span>
        )}
        {node.task_type_name}
      </div>
      {hasChildren && !collapsed && (
        <ul className="list-unstyled fw-medium" style={{ marginLeft: 20 }}>
          {node.children.map(child => (
            <TreeNode key={child.task_type_id} node={child} onDragStart={onDragStart} />
          ))}
        </ul>
      )}
    </li>
  );
};


const Sidebar_1 = () => {
  const onDragStart = (event, nodeType, nodeName, task_type_id) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify({ nodeType, nodeName, task_type_id }));
    // event.dataTransfer.setData('nodeName', nodeName);
    event.dataTransfer.effectAllowed = 'move';
  };

  const dispatch = useDispatch();

  const TaskTypeProperties = createSelector(
    (state) => state.tasktypes,
    (TaskTypes) => ({
      tasktypes: TaskTypes.tasktypes,
      loading: TaskTypes.loading,
      error: TaskTypes.error,
    })
  );

  const {
    tasktypes, loading
  } = useSelector(TaskTypeProperties);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    dispatch(onGetTasks());
  }, [dispatch]);
  

  useEffect(() => {
    if (tasktypes) {
      setFilteredTasks(filterTasks(tasktypes, searchQuery));
    }
  }, [tasktypes, searchQuery]);
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter tasks based on search query
  const filterTasks = (tasks, query) => {
    if (!query) return tasks;

    const search = (task) => {
      if (task.task_type_name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      if (task.children) {
        return task.children.some(search);
      }
      return false;
    };

    return tasks.filter(search);
  };

  const taskTree = tasktypes? buildTree(filteredTasks) : [];

  return (
    <aside>
      
      <div className="search-box">       
        <div className="position-relative">
          <input
            type="text"
            className="form-control rounded bg-light border-light"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <i className="mdi mdi-magnify search-icon"></i>
        </div>
      </div>
      <hr className="my-2" />
      <ul className="list-unstyled fw-medium">
        {taskTree.length > 0 ? (
          taskTree.map(root => (
            <TreeNode key={root.task_type_id} node={root} onDragStart={onDragStart} />
          ))
        ) : (
          <li>No tasks available</li>
        )}
      </ul>
    </aside>
  );
};

Sidebar_1.displayName = 'Sidebar_1';

export default Sidebar_1;
