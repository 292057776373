const oktaConfig = {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID, //'0oajzbmidzGh6DDT85d7',  
    issuer: process.env.REACT_APP_OKTA_ISSUER,//'https://dev-50223343.okta.com/oauth2/default',  
    redirectUri: window.location.origin + '/login/callback',  
    scopes: ['openid', 'profile', 'email'],  // Default scopes required for Okta
    pkce: true,  // Enforce PKCE for security
    //disableHttpsCheck: true,
  };

 export default oktaConfig
