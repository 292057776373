import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from "reselect";
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {  Input, Label, FormText, Button } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "components/Common/Spinner";
import { GetWorkflowTaskConfigList as onGetTaskConfiguration } from '../../../store/taskconfiguration/actions';
import { UpdateWorkflowTaskConfig, UpdateWorkflowTaskConfigFail } from '../../../store/taskconfiguration/actions';

const TaskConfiguration = ({ taskId, task_type_id, onConfigUpdate, onCancel, editId, nodeLabel }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [showHelp, setShowHelp] = useState(false);
  const [label, setLabel] = useState(nodeLabel);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const taskConfigurationSelector = createSelector(
    state => state.wf_taskConfiguration,
    wfconfig => {
      const sortedConfigurations = [...wfconfig.wf_taskConfiguration].sort((a, b) => {
        if (a.required === "Required" && b.required !== "Required") {
          return -1;
        }
        if (a.required !== "Required" && b.required === "Required") {
          return 1;
        }
        return 0;
      });
      return {
        wf_taskConfiguration: sortedConfigurations,
        loading: wfconfig.loading,
      };
    }
  );

  const { wf_taskConfiguration, loading: wfLoading } = useSelector(taskConfigurationSelector);
  const taskUpdateSuccess = useSelector(state => state.wf_taskConfiguration.success);

  useEffect(() => {
    if (taskId) {
      console.log("Dispatching onGetTaskConfiguration");
      dispatch(onGetTaskConfiguration(editId, taskId, task_type_id));
    }
  }, [editId, taskId, task_type_id, dispatch]);

  useEffect(() => {
    setIsLoading(wfLoading);
  }, [wfLoading]);

  
  const initialValues = wf_taskConfiguration?.reduce((acc, config) => {
    if (config.field_datatype === 'boolean') {
      // Parse 'true' or 'false' strings into actual booleans
      acc[config.field_label] = config.field_value === true;
      //acc[config.field_label] = config.field_value === true ? true : false;
    } else {
      acc[config.field_label] = config.field_value;
    }
    return acc;
  }, {}) || {};

  console.log("Initial Values: ", initialValues);

  const validationSchema = Yup.object(
    wf_taskConfiguration?.reduce((acc, config) => {
      if (config.required === 'Required') {
        acc[config.field_label] = config.field_datatype === 'boolean'
          ? Yup.boolean().oneOf([true], 'This field is required')
          : Yup.string().required('This field is required');
      }
      return acc;
    }, {})
  );


  useEffect(() => {
    if (taskUpdateSuccess) {
      onConfigUpdate(taskUpdateSuccess, label);
      dispatch(UpdateWorkflowTaskConfigFail(errors)); // Reset success flag
    }
  }, [taskUpdateSuccess, onConfigUpdate, label, dispatch]);

  
  const handleTaskConfigSubmit = (values, { setSubmitting, setErrors }) => {
    // Example validation: check if all required fields have values
    let isValid = true;
    const errors = {};
  
    wf_taskConfiguration.forEach((config) => {
      if (config.required === 'Required' && !values[config.field_label]) {
        isValid = false;
        errors[config.field_label] = 'This field is required';
      }
    });
  
    if (!isValid) {
      setErrors(errors);
      setSubmitting(false);
      return;
    }
  
    const updatedTaskConfiguration = wf_taskConfiguration.map((field) => ({
      field_label: field.field_label,
      field_value: values[field.field_label],
    }));
  
    // Create the key-value pair dictionary
    const configDictionary = updatedTaskConfiguration.reduce((acc, field) => {
      acc[field.field_label] = field.field_value;
      return acc;
    }, {});
  
    const taskConfigData = {
      Worflow_id: editId,
      task_id: taskId,
      tasktype_id: task_type_id,
      task_sequence: 0, // Adjust if needed
      task_fields: configDictionary,
      task_config: null,
      child_task_id: null,
    };
  
    dispatch(UpdateWorkflowTaskConfig(taskConfigData));
    setSubmitting(false);
  };
  
  const handleEditClick = () => {
    setIsEditing(true);
  };


  const handleCancelClick = () => {
    setIsEditing(false);
    setLabel(nodeLabel); // Reset to original label if canceled
  };

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };


  return (
    <React.Fragment>
      <div className="right-bar" id="right-bar" style={{ width: '500px' }}>
        <SimpleBar style={{ height: "100vh" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  onCancel();
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
             
              <h5>
                {!isEditing ? (
                  <>
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <span>{nodeLabel}</span>
                      <span className="infoButton text-success" onClick={handleEditClick} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                        <strong> <i className="mdi mdi-square-edit-outline font-size-18" /></strong>
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <span style={{ width: '300px' }}>
                        <Input type="text" value={label} onChange={(e) => setLabel(e.target.value)} />
                      </span>
                      <span className="infoButton text-danger" onClick={handleCancelClick} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                        <i className="mdi mdi-close-circle font-size-18" />
                      </span>
                    </span>
                  </>
                )}
               
              </h5>
            </div>
            <hr className="my-0" />
            <div className="p-4">
              {isLoading ? (
                <Spinners setLoading={setIsLoading} />
              ) : (
                <>
                <span onClick={toggleHelp} className="float-end" style={{
                    cursor: 'pointer', marginLeft: '10px', position: 'relative',
                    top: '-20px'
                  }}>
                  <i className="mdi mdi-help-circle font-size-18" />
                </span>                  
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleTaskConfigSubmit}
                  enableReinitialize={true}
                >
                  {({ isSubmitting,values,handleChange }) => (
                    <Form>
                      {wf_taskConfiguration?.map(config => (
                        <div key={config.field_label} className="mb-3">

                          {(config.field_datatype === 'string' || config.field_datatype === 'int') && (<Label htmlFor={config.field_label}>
                            {config.field_label} {config.required === 'Required' && <span style={{ color: 'red' }}>*</span>}
                          </Label>)}
                          {config.field_datatype === 'string' ? (
                            <Field
                              type="text"
                              id={config.field_label}
                              name={config.field_label}
                              className="form-control"
                            />
                          ) : config.field_datatype === 'boolean' ? (
                              <div>
                                <Field
                                  type="checkbox"
                                  id={config.field_label}
                                  name={config.field_label}
                                  className="form-check-input"
                                  checked={values[config.field_label]}
                                  onChange={e => {
                                    handleChange({
                                      target: {
                                        name: config.field_label,
                                        value: e.target.checked
                                      }
                                    });
                                  }}
                                />                              
                                <Label htmlFor={config.field_label} style={{marginLeft:"4px"}}>
                                  {config.field_label} {config.required === 'Required' && <span style={{ color: 'red' }}>*</span>}
                                </Label>                                
                              </div>
                          ) : config.field_datatype === 'int' ? (
                            <Field
                              type="number"
                              id={config.field_label}
                              name={config.field_label}
                              className="form-control"
                              validate={(value) => {
                                if (!Number.isInteger(Number(value))) {
                                  return 'Please enter a valid integer';
                                }
                              }}
                            />
                          ) : null}
                          <ErrorMessage name={config.field_label} component="div" style={{ color: 'red' }} />
                          {showHelp && (<FormText>{config.description}</FormText>)}
                        </div>
                      ))}
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                        <button type="submit" className="btn btn-success"style={{ marginRight: '5px' }} disabled={isSubmitting}>
                          Save
                        </button>
                        <button type="button" className="btn btn-danger" onClick={onCancel}>
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                </>
              )}
            </div>
            <ToastContainer />
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
}

export default TaskConfiguration;
