import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Container, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { viewFileRequest as onViewFile } from 'store/actions';

//import axios from 'axios';

const FileViewerPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { file } = location.state || {};  // Extract the file passed through route state
    const [fileUrl, setFileUrl] = useState(null);
    const [isFileLoading, setIsFileLoading] = useState(true);

    const filemanagerProperties = createSelector(
        (state) => state.filemanager,
        (filemanager) => ({
            // fileUrl: filemanager.fileUrl,
            loading: filemanager.loading,
            fileBlob: filemanager.fileBlob
        })
    );

    // const { fileUrl, loading } = useSelector(filemanagerProperties);
    // console.log(fileUrl)
    const { fileBlob, loading } = useSelector(filemanagerProperties);
    useEffect(() => {
        setIsFileLoading(true);
        setFileUrl(null);
        if (file) {
            dispatch(onViewFile(file.bucket_name, file.object_key));
            console.log(file.bucket_name, file.object_key)
        }
    }, [dispatch, file]);

    useEffect(() => {
        if (fileBlob) {
            const url = window.URL.createObjectURL(fileBlob);
            setFileUrl(url);
            setIsFileLoading(false); 
            // Clean up URL object when the component unmounts or when the file changes
            return () => {
                if (url) {
                    window.URL.revokeObjectURL(url);
                }
            };
        }
    }, [fileBlob]);
    
    if (!file) {
        return <div>No file to display</div>;
    }

    if (isFileLoading) {
        return <div>Loading file...</div>;
    }

    // Function to handle file download
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.file_name || 'downloaded_file'; // Use the file name from the file object or fallback to a default name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element after download
    };

    // Function to open file in a new tab
    const openInNewTab = () => {
        window.open(fileUrl, '_blank');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="File List" breadcrumbItem="File Detail" link="/fileslist"/>
                    <Card>
                        <CardBody className="border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1">Viewing File: {file.file_name}</h5>
                                <div className="flex-shrink-0">
                                    <Button color="primary" onClick={handleDownload} className="btn me-1 mt-3">
                                        Download
                                    </Button>
                                    <Button color="secondary" onClick={openInNewTab} className="mt-3">
                                        Open in New Tab
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                        {fileUrl && file.file_type && (
                            <FileViewer
                                key={file.object_key} // Add this key prop to force re-render on file change
                                fileType={file.file_type}
                                filePath={fileUrl} // Use the correct file URL fetched from the API
                                onError={(e) => console.error("Error viewing file:", e)}
                            />
                        )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FileViewerPage;

