import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputMask from 'react-input-mask';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { getCompanyId } from "../../../helpers/fakebackend_helper"

const ContactsList = () => {

  //meta title
  document.title = "User List";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [companyId, setCompanyId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      first_name: (contact && contact.first_name) || "",
      last_name: (contact && contact.last_name) || "",
      email: (contact && contact.email) || "",
      phonenumber: (contact && contact.phonenumber) || "",
      is_active:  contact?.is_active !== undefined ? (contact.is_active ? "true" : "false") : "true", 
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string()
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Please Enter Valid Email"
        )
        .required("Please Enter Your Email"),
      phonenumber: Yup.string().required("Please Enter Your Phone Number")
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid'),
      is_active: Yup.string().oneOf(["true", "false"]).required("Please select a status")
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          company_id: companyId,
          user_id: contact.user_id,
          first_name: values.first_name,
          last_name: values.last_name,
          email: contact.email,
          phonenumber: values.phonenumber,
          user_type: contact.user_type,
          is_active: values.is_active,
          created_at: contact.created_at,
        };
        // Update user
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          company_id: companyId,
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          phonenumber: values["phonenumber"],
          password: Math.random().toString(10).substr(2, 9),
          user_type: 'member',
          is_active: values.is_active === "true",
          created_at: new Date().toISOString()
        };
        // Save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  useEffect(() => {
    const fetchCompanyId = async () => {
      const id = await getCompanyId(); // Call the function to get companyId
      setCompanyId(id);
      dispatch(onGetUsers());
    };

    fetchCompanyId();
  }, [dispatch]);

  const ContactsProperties = createSelector(
    (state) => state.contacts,
    (Contacts) => ({
      users: Contacts.users,
      loading: Contacts.loading
    })
  );

  const {
    users, loading
  } = useSelector(ContactsProperties);

  const [modal, setModal] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
    
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
    
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
    
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setContact({
      user_id: user.user_id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phonenumber: user.phonenumber,
      user_type: user.user_type,
      is_active: user.is_active,
      created_at: user.created_at,

    });
    setIsEdit(true);

    toggle();
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users) => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.user_id) {
      dispatch(onDeleteUser(contact.user_id, companyId));
    }
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  const handleIsActiveChange = (event) => {
    const value = event.target.value === "true" ? true : event.target.value === "false" ? false : "";
    validation.setFieldValue('is_active', value);
  };

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "user_id",
        cell: (cell) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {cell.row.original.first_name.charAt(0)}
            </span>
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name",
        accessorKey: "first_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {cell.getValue()} {cell.row.original.last_name}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Phone Number",
        accessorKey: "phonenumber",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "User Type",
        accessorKey: "user_type",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "is_active",
        cell: (cell) => (
          <span
            className={
              cell.getValue() ? "badge bg-success" : "badge bg-danger"
            }
          >
            {cell.getValue() ? "Active" : "Inactive"}
          </span>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => new Date(cell.getValue()).toLocaleString(),
      },
      {
        header: "Action",
        cell: (cellProps) => {
          const userType = cellProps.row.original.user_type;
          return (
            <div className="d-flex gap-3">
              {userType !== 'admin' && (
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Link>
              )}
              {userType !== 'admin' && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                >
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        text="Are you sure you want to delete this user?"
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admin" breadcrumbItem="Users List" />
          <Row>
            {isLoading ? <Spinners setLoading={setLoading} />
              :
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={users || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      SearchPlaceholder="Search..."
                      isCustomPageSize={true}
                      isAddButton={true}
                      handleUserClick={handleUserClicks}
                      buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                      buttonName="New User"
                      tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      theadClass="table-light"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      pagination="pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            }

            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit User" : "Add User"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >

                  <Row>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">First Name</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          name="first_name"
                          type="text"
                          placeholder="Insert First Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                              validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                          validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Last Name</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          name="last_name"
                          label="Last Name"
                          placeholder="Insert Last Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                              validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                          validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          placeholder="Insert Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                              validation.errors.email
                              ? true
                              : false
                          }
                          readOnly={!!isEdit}
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Phone Number</Label>
                        <span style={{ color: "red" }}>*</span>
                        <InputMask
                          mask="(999) 999-9999"
                          value={validation.values.phonenumber}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        >
                          {(inputProps) => (
                            <Input
                              {...inputProps}
                              name="phonenumber"
                              type="text"
                              placeholder="Insert Phone Number"
                              invalid={
                                validation.touched.phonenumber &&
                                  validation.errors.phonenumber
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </InputMask>
                        {validation.touched.phonenumber &&
                          validation.errors.phonenumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phonenumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                      
                      {isEdit ? <>
                        <div className="mb-3">
                          <Label className="form-label">Is Active</Label>
                          <Input
                            name="is_active"
                            type="select"
                            onChange={handleIsActiveChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.is_active !== undefined ? validation.values.is_active.toString() : ""}
                            invalid={
                              validation.touched.is_active && validation.errors.is_active
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </Input>
                          {validation.touched.is_active && validation.errors.is_active ? (
                            <FormFeedback type="invalid">
                              {validation.errors.is_active}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </> : ''}
                     
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
