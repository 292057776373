import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { toast } from "react-toastify";

// Login Redux States
import { FORGET_PASSWORD,VERIFY_OTP } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError,
  verifyOtpSuccess,verifyOtpError
} from "./actions"

//Include Both Helper File with needed methods
import {
  requestForgotPasswordAPI,
  requestVerifyOtpAPI
} from "../../../helpers/fakebackend_helper"

function* forgetUser({ payload: user }){
  try {
    const response = yield call(requestForgotPasswordAPI, user);
    if(!response.IsError){
      yield put(userForgetPasswordSuccess(response.Data))
    }
    else{
      yield put(userForgetPasswordError(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
    toast.error("Contact Updated Failed", { autoClose: 2000 });
  }
}

function* verifyOtp({ payload: data }) {
  try {
    const response = yield call(requestVerifyOtpAPI, data);
    if (!response.IsError) {
      yield put(verifyOtpSuccess());
    } else {
      yield put(verifyOtpError(response.ErrorMessage));
    }
  } catch (error) {
    yield put(verifyOtpError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(VERIFY_OTP, verifyOtp);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
