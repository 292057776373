import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link,useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Label, Input, FormFeedback, Alert, Form } from 'reactstrap';
import { userForgetPassword, verifyOtp } from '../../store/actions'; 
import profile from '../../assets/images/profile-img.png'; 
import logo from '../../assets/images/logo.svg'; 

const ForgetPasswordPage = props => {
  document.title = "Forget Password | Dataos";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword({email:values.email}));
    }
  });

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyOtp({ user_id: userId, otp:otp }));
  };

  const ForgotPasswordProperties = createSelector(
    (state) => state.ForgetPassword,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
      userId: forgetPassword.userId,
      otpError: forgetPassword.otpError,
      otpSuccess: forgetPassword.otpSuccess
    })
  );

  const {
    forgetError,
    forgetSuccessMsg,
    userId,
    otpError,
    otpSuccess
  } = useSelector(ForgotPasswordProperties);

  useEffect(() => {
    if (otpSuccess) {
      navigate('/reset-password');
    }
  }, [otpSuccess, navigate]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to DataOS.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    {!userId ? (
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      <Form
                        className="form-horizontal"
                        onSubmit={handleOtpSubmit}
                      >
                        <div className="mb-3">
                          <Label className="form-label">OTP</Label>
                          <Input
                            name="otp"
                            className="form-control"
                            placeholder="Enter OTP"
                            type="text"
                            onChange={(e) => setOtp(e.target.value)}
                            value={otp}
                            invalid={!!otpError}
                            required
                          />
                          {otpError && (
                            <FormFeedback type="invalid">{otpError}</FormFeedback>
                          )}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Verify OTP
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} DataOS. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by DataOS
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
