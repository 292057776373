//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";
export const COMPANY_REGISTER = "/register_company";
export const ACTIVATE_ACCOUNT = "/activate-account";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//OKTA LOGIN
export const OKTA_LOGIN = "/okta-validate-user";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/register_user";
export const UPDATE_USER = "/update_user";
export const DELETE_USER = "/delete_user";

//workflows
export const WORKFLOWS = "/workflows";

//tasktypes
export const TASKTYPES = "/tasktypes";

// // Task Configurations
export const GET_TASK_CONFIGURATION_REQUEST = "/workflow/get_task_configuration";

//Forgot Password
export const FORGET_PASSWORD = "/forgot-password";
export const VERIFY_OTP_API= "/verify-otp";
export const RESET_PASSWORD = "/reset-password";

//Change Password
export const CHANGE_PASSWORD = "/change-password";

//dashboard
export const DASHBOARD_API = "/fetch-dashboard";

//file manager
export const GET_FILES = "/s3events/get_s3event_docs";
export const VIEW_FILE = "/s3events/fetch_file";
export const GET_FILTERED_FILES = "/s3events/search-s3-event-logs";
export const GET_CUSTOM_FILTERS_LIST = "/search-attributes/get_attributes";
export const ADD_CUSTOM_FILTER ="/search-attributes/create";
export const UPDATE_CUSTOM_FILTER ="/search-attributes/update";
export const DELETE_CUSTOM_FILTER = "/search-attributes/delete";
export const GET_CUSTOM_FILTER_META_DATA = "/s3events/fetch-file-metadata";
export const UPDATE_CUSTOM_FILTER_META_DATA = "s3events/update_file_metadata"

