import { call, put, takeEvery, delay } from "redux-saga/effects"
import {
  GET_FILES,
  VIEW_FILE,
  GET_FILTERED_FILES,
  GET_CUSTOM_FILTERS_LIST,
  ADD_CUSTOM_FILTER,
  UPDATE_CUSTOM_FILTER,
  DELETE_CUSTOM_FILTER,
  GET_CUSTOM_FILTER_META_DATA,
  UPDATE_CUSTOM_FILTER_META_DATA
} from "./actionTypes"
import {
  getFilesSuccess,
  getFilesFail,
  viewFileSuccess,
  viewFileFail,
  getFilteredFilesSuccess,
  getFilteredFilesFail,
  getCustomFiltersListSuccess,
  getCustomFiltersListFail,
  addCustomFilterSuccess,
  addCustomFilterFail,
  updateCustomFilterSuccess,
  updateCustomFilterFail,
  deleteCustomFilterSuccess,
  deleteCustomFilterFail,
  getCustomFilterMetaDataSuccess,
  getCustomFilterMetaDataFail,
  updateCustomFilterMetaDataSuccess,
  updateCustomFilterMetaDataFail
} from "./actions"
// toast
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  getFiles, 
  viewFileRequest, 
  getFilteredFiles, 
  getCustomFiltersList, 
  addCustomFilter, 
  updateCustomFilter, 
  deleteCustomFilter,
  getCustomFilterMetaData,
  updateCustomFilterMetaData 
} from "helpers/fakebackend_helper";


function* fetchFiles({ payload }) {
  try {
    const response = yield call(getFiles,payload);
    if (!response.IsError) {
      yield put(getFilesSuccess(response))
    }
    else {
      yield put(getFilesFail(response.ErrorMessage))
    }
  } catch (error) {
    yield put(getFilesFail(error))
  }
}

function* onViewFile({ payload }) {
  try {
    const { bucketName, objectKey } = payload;
    const response = yield call(viewFileRequest, bucketName, objectKey);

    if (response && response instanceof Blob) { // Ensure it's a Blob
      yield put(viewFileSuccess(response));
    } else {
      yield put(viewFileFail('Invalid Blob'));
    }
  } catch (error) {
    yield put(viewFileFail(error));
    console.error("Error viewing file:", error);
  }
}

function* fetchFilteredFiles({ payload: filterDetails }) {
  debugger;
  try {
    const response = yield call(getFilteredFiles, filterDetails);
    console.log(response);
    if (response.IsError) {
      yield put(getFilteredFilesFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(getFilteredFilesSuccess(response));
      toast.success(response.SuccessMessage, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(getFilteredFilesFail(error));
    toast.error("Fetch Filtered Files Failed", { autoClose: 2000 });
  }
}

// fetch customfilters lists
function* fetchCustomFiltersList() {
  try {
    const response = yield call(getCustomFiltersList);
    if (response.IsError) {
      yield put(getCustomFiltersListFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(getCustomFiltersListSuccess(response.Data));
      toast.success(response.SuccessMessage, { autoClose: 2000 });
    }
  }
  catch (error) {
    yield put(getCustomFiltersListFail(error));
    toast.error("Fetch Custom Filters List Failed", { autoClose: 2000 });
  }
}

function* onAddNewCustomFilter({ payload: customFilterData }) {debugger;
  try {
    const response = yield call(addCustomFilter, customFilterData);   
    if (response.IsError) {debugger;
      yield put(addCustomFilterFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 })
    } else {
      yield put(addCustomFilterSuccess(response.Data))
      toast.success("Custom Filter Added Successfully", { autoClose: 2000 });
    }
  }
  catch (error) {
    yield put(addCustomFilterFail(error));
    toast.error("Custom Filter Adding Failed", { autoClose: 2000 });
  }

}

function* onUpdateCustomFilter({ payload: customFilterData }) {
  try {
    const response = yield call(updateCustomFilter, customFilterData);
    if (!response.IsError) {
      yield put(updateCustomFilterSuccess(customFilterData));
      toast.success("Filter Updated Successfully", { autoClose: 2000 });
    } else {
      yield put(updateCustomFilterFail(response.ErrorMessage));
      toast.success(response.ErrorMessage, { autoClose: 2000 });      
    }
  } catch (error) {
    yield put(updateCustomFilterFail(error));
    toast.error("Custom Filter Update Failed", { autoClose: 2000 });
  }
}

function* onDeleteCustomFilter({ payload: { attribute_id } }) {
  try {
    const response = yield call(deleteCustomFilter, attribute_id);
    if (response.IsError) {
      yield put(deleteCustomFilterFail(response.ErrorMessage))
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }
    else {
      yield put(deleteCustomFilterSuccess(attribute_id))
      toast.success("Custom Filter Deleted Successfully", { autoClose: 2000 });

    }

  } catch (error) {
    yield put(deleteCustomFilterFail(error))
    toast.error("Custom Filter Deleted Failed", { autoClose: 2000 });
  }
}

function* onFetchCustomFilterMetaData({ payload: file_id}) {
  try {
    const response = yield call(getCustomFilterMetaData, file_id);
    if (response.IsError) {
      yield put(getCustomFilterMetaDataFail(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(getCustomFilterMetaDataSuccess(response.Data));
    }
  } catch (error) {
    yield put(getCustomFilterMetaDataFail(error));
    toast.error("Fetching Task Configuration Failed", { autoClose: 2000 });
  }
}

function* onUpdateCustomFilterMetaData({ payload: metadata }) {
  try {
    const response = yield call(updateCustomFilterMetaData, metadata);
    if (!response.IsError) {
      yield put(updateCustomFilterMetaDataSuccess(metadata));
      toast.success("File Metadata Updated Successfully", { autoClose: 2000 });
    } else {
      yield put(updateCustomFilterMetaDataFail(response.ErrorMessage));
      toast.success(response.ErrorMessage, { autoClose: 2000 });
      //yield put(getCustomFilterMetaData());
    }
  } catch (error) {
    yield put(updateCustomFilterMetaDataFail(error));
    toast.error("File Metadata Update Failed", { autoClose: 2000 });
  }
}


function* filemanagerSaga() {
  yield takeEvery(GET_FILES, fetchFiles)
  yield takeEvery(VIEW_FILE, onViewFile)
  yield takeEvery(GET_FILTERED_FILES, fetchFilteredFiles)
  yield takeEvery(GET_CUSTOM_FILTERS_LIST, fetchCustomFiltersList)
  yield takeEvery(ADD_CUSTOM_FILTER, onAddNewCustomFilter)
  yield takeEvery(UPDATE_CUSTOM_FILTER, onUpdateCustomFilter)
  yield takeEvery(DELETE_CUSTOM_FILTER, onDeleteCustomFilter)
  yield takeEvery(GET_CUSTOM_FILTER_META_DATA, onFetchCustomFilterMetaData)
  yield takeEvery(UPDATE_CUSTOM_FILTER_META_DATA, onUpdateCustomFilterMetaData)
}

export default filemanagerSaga;
