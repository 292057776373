export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const VALIDATE_OKTA_TOKEN_REQUEST = 'VALIDATE_OKTA_TOKEN_REQUEST';
export const VALIDATE_OKTA_TOKEN_SUCCESS = 'VALIDATE_OKTA_TOKEN_SUCCESS';
export const VALIDATE_OKTA_TOKEN_FAILURE = 'VALIDATE_OKTA_TOKEN_FAILURE';
