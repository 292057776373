import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
  } from "./actionTypes"
  
export const changePasswordRequest = (payload) => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload,
  });
  
  export const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS,
  });
  
  export const changePasswordFailure = (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    error,
  });