import axios from 'axios';
const API_URL = process.env.REACT_APP_APIKEY;

const fetch_pdf_file = async (bucket, object_key) => {debugger;
    try {
        // Making a GET request to fetch the PDF file from S3
        const response = await axios.get(`${API_URL}/s3events/fetch_file`, {
            params: {
                bucket_name: bucket,
                object_key: object_key,
            },
            responseType: 'blob', // To handle binary data (PDF)
            headers: {
                'Content-Type': 'application/json', // API expects JSON request
            },
        });

        // Check if the response status is 200 (OK) before proceeding
        if (response.status === 200) {
            // Return the response as Blob
            return response.data;
        } else {
            throw new Error(`Unexpected response status: ${response.status}`);
        }
    } catch (error) {
        if (error.response) {
            // Error with a response from the server (HTTP error status)
            console.error('Server Error:', error.response.data);
            throw new Error(
                error.response.data.message || 'Error fetching PDF file from the server'
            );
        } else if (error.request) {
            // Request was made, but no response received (network issue)
            console.error('Network Error:', error.request);
            throw new Error('Network error: No response received from the server');
        } else {
            // General error, maybe something went wrong setting up the request
            console.error('Error:', error.message);
            throw new Error('An error occurred: ' + error.message);
        }
    }
};

export default fetch_pdf_file;
