import { call, put, takeEvery } from "redux-saga/effects"
import { 
  GET_TASKS,GET_WORKFLOWS, 
  GET_TASK_CONFIGURATION_REQUEST, 
  ADD_NEW_WORKFLOW,    
  UPDATE_WORKFLOW,
  GET_WORKFLOW_CONFIG, 
  UPDATE_WORKFLOW_CONFIG,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_NODE_CONFIG
 } from "./actionTypes"
import {
    getWorkflows,
    getWorkflowsSuccess,
    getWorkflowsFail,
    addWorkflowSuccess,
    addWorkflowFail,
    updateWorkflowSuccess,
    updateWorkflowFail,
    getWorkflowConfigSuccess,
    getWorkflowConfigFail,
    updateWorkflowConfigSuccess,
    updateWorkflowConfigFail,
    deleteWorkflowSuccess,
    deleteWorkflowFail,
    deleteWorkflowNodeConfigSuccess,
    deleteWorkflowNodeConfigFail
} from "./actions"
import { getWorkflowsApi, addNewWorkflowApi, updateWorkflow, getTaskConfiguration, getWorkflowConfig, 
  updateWorkflowConfigApi, deleteWorkflowConfigApi, deleteWorkflowNodeConfig  } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"


  function* fetchWorkflows() {
    try {
      const response = yield call(getWorkflowsApi);
      if (!response.IsError) {
        yield put(getWorkflowsSuccess(response.Data))
      }
      else{
        yield put(getWorkflowsFail(response.ErrorMessage))
      }
    } catch (error) {
      yield put(getWorkflowsFail(error))
    }
  }

  function* onAddNewWorkflow({ payload: workflowData }) {debugger;
    try {
      const response = yield call(addNewWorkflowApi, workflowData);
      if (response.IsError) {
        yield put(addWorkflowFail(response.ErrorMessage));
        toast.error(response.ErrorMessage, { autoClose: 2000 });
      } else {
        yield put(addWorkflowSuccess(response.Data));
        toast.success(response.SuccessMessage, { autoClose: 2000 }); 
        yield put(getWorkflows());
      }
    } catch (error) {
      yield put(addWorkflowFail(error));
      toast.error("Workflow Added Failed", { autoClose: 2000 });
    }
  }

  function* onUpdateWorkflow({ payload: workflowData }) {
    try {    
    const response = yield call(updateWorkflow, workflowData);
      if (!response.IsError) {
        yield put(updateWorkflowSuccess(workflowData));
        toast.success("Workflow Updated Successfully", { autoClose: 2000 }); 
      } else {
        yield put(updateWorkflowFail(response.ErrorMessage));
        toast.success(response.ErrorMessage, { autoClose: 2000 }); 
        yield put(getWorkflows());
      }
    } catch (error) {
      yield put(updateWorkflowFail(error));
      toast.error("Workflow Update Failed", { autoClose: 2000 });
    }
    
  }  

  function* fetchWorkflowConfig({payload: configId }) {debugger;
    try {
      const response = yield call(getWorkflowConfig, configId);
      console.log(response)
      if (!response.IsError) {
        yield put(getWorkflowConfigSuccess(response.Data))
      }
      else{
        yield put(getWorkflowConfigFail(response.ErrorMessage))
      }
    } catch (error) {
      yield put(getWorkflowConfigFail(error))
    }
  }

  function* onUpdateWorkflowConfig({ payload: configDetails}) {
    try {    
    const response = yield call(updateWorkflowConfigApi, configDetails);
      if (!response.IsError) {
        yield put(updateWorkflowConfigSuccess(configDetails));
        toast.success("Workflow Updated Successfully", { autoClose: 2000 }); 
        yield put(getWorkflows());
      } else {
        yield put(updateWorkflowConfigFail(response.ErrorMessage));
        toast.error(response.ErrorMessage, { autoClose: 2000 }); 
      }
    } catch (error) {
      yield put(updateWorkflowConfigFail(error));
      toast.error("Workflow Update Failed", { autoClose: 2000 });
    }
  }

  function* onDeleteWorkflowConfig({ payload: configDetails}) {debugger;
    try {    
      const response = yield call(deleteWorkflowConfigApi, configDetails);
        if (!response.IsError) {
          yield put(deleteWorkflowSuccess(configDetails));
          toast.success("Workflow Deleted Successfully", { autoClose: 2000 }); 
          yield put(getWorkflows());
        } else {
          yield put(deleteWorkflowFail(response.ErrorMessage));
          toast.error(response.ErrorMessage, { autoClose: 2000 }); 
        }
      } catch (error) {
        yield put(deleteWorkflowFail(error));
        toast.error("Workflow Delete Failed", { autoClose: 2000 });
      }
  }

  // for deleting node 
  function* onDeleteWorkflowNodeConfig({ payload: deleteConfigDetails}) {debugger;
    try {    
      const response = yield call(deleteWorkflowNodeConfig, deleteConfigDetails);
        if (!response.IsError) {
          yield put(deleteWorkflowNodeConfigSuccess(deleteConfigDetails));
          toast.success("Node Deleted Successfully", { autoClose: 2000 }); 
          yield put(getWorkflows());
        } else {
          yield put(deleteWorkflowNodeConfigFail(response.ErrorMessage));
          toast.error(response.ErrorMessage, { autoClose: 2000 }); 
        }
      } catch (error) {
        yield put(deleteWorkflowNodeConfigFail(error));
        toast.error("Node Deleted Failed", { autoClose: 2000 });
      }
  }

  function* workflowsSaga() {
    yield takeEvery(GET_WORKFLOWS, fetchWorkflows)
    yield takeEvery(ADD_NEW_WORKFLOW, onAddNewWorkflow)
    yield takeEvery(UPDATE_WORKFLOW, onUpdateWorkflow)
    yield takeEvery(GET_WORKFLOW_CONFIG, fetchWorkflowConfig)
    yield takeEvery(UPDATE_WORKFLOW_CONFIG, onUpdateWorkflowConfig)
    yield takeEvery(DELETE_WORKFLOW, onDeleteWorkflowConfig)
    yield takeEvery( DELETE_WORKFLOW_NODE_CONFIG, onDeleteWorkflowNodeConfig)
  }

  export default workflowsSaga