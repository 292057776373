import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; // Ensure useSelector is imported
import { activateAccount } from "../../../src/store/auth/register/actions"; 
import { Alert, Spinner } from "reactstrap";
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const ActivateAccountPage = () => {
  const [activationSuccess, setActivationSuccess] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, accountValidate, accountValidateError } = useSelector(state => state.account || {});
  
  // Extract token from URL
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      dispatch(activateAccount(token));
      setActivationSuccess(true);
    } else {
      setActivationSuccess(false);
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!loading) {
      if (accountValidate) {
        setActivationSuccess(true);
      } else {
        setActivationSuccess(false);
      }
    }
  }, [loading, accountValidate, accountValidateError]);

  // Handle different states: loading, success, and error
  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
        <p>Activating your account, please wait...</p>
      </div>
    );
  }

  return (
    <div className="text-center mt-5">
  <div className="account-pages my-5 pt-sm-5">
    <Container>
      <Row>
        <Col lg={12}>
          <div className="d-block text-center mb-5 text-muted">
            <h4 className="mb-sm-0 font-size-18">DataOS</h4>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <Card>
            {activationSuccess ? (
              <CardBody>
                <div className="p-2">
                  <div className="text-center">
                    <div className="avatar-md mx-auto">
                      <div className="avatar-title rounded-circle bg-light">
                        <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                      </div>
                    </div>
                    <div className="p-2 mt-4">
                      <h4>Success!</h4>
                      <p className="text-muted">
                        Your account has been successfully activated!
                      </p>
                      <div className="mt-4">
                        <Link to="/login" className="btn btn-success">
                          Back to Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <div className="p-2">
                  <div className="text-center">
                    <div className="avatar-md mx-auto">
                      <div className="avatar-title rounded-circle bg-light">
                        <i className="bx bx-error h1 mb-0 text-danger"></i>
                      </div>
                    </div>
                    <div className="p-2 mt-4">
                      <h4>Error!</h4>
                      <p className="text-muted">
                        There was an issue activating your account. The token may be invalid 
                        or expired. Please try the following options:
                      </p>
                      {/* <ul className="text-muted text-left">
                        <li>Double-check the activation link for any errors.</li>
                        <li>Contact support if the issue persists.</li>
                        <li>Request a new activation link.</li>
                      </ul> */}
                      <div className="mt-4">
                        {/* <Link to="/resend-activation" className="btn btn-warning mr-2">
                          Resend Activation Link
                        </Link> */}
                        <Link to="/support" className="btn btn-info">
                          Contact Support
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
</div>

  );
};

export default ActivateAccountPage;
