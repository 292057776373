/* TASKS */
export const GET_TASKS_TYPES = "GET_TASKS_TYPES"
export const GET_TASKS_TYPES_SUCCESS = "GET_TASKS_TYPES_SUCCESS"
export const GET_TASKS_TYPES_FAIL = "GET_TASKS_TYPES_FAIL"

/**
 * add task
 */
export const ADD_NEW_TASK_TYPES = "ADD_NEW_TASK_TYPES"
export const ADD_TASK_TYPES_SUCCESS = "ADD_TASK_TYPES_SUCCESS"
export const ADD_TASK_TYPES_FAIL = "ADD_TASK_TYPES_FAIL"

/**
 * Edit task
 */
export const UPDATE_TASK_TYPES = "UPDATE_TASK_TYPES"
export const UPDATE_TASK_TYPES_SUCCESS = "UPDATE_TASK_TYPES_SUCCESS"
export const UPDATE_TASK_TYPES_FAIL = "UPDATE_TASK_TYPES_FAIL"

/**
 * Delete task
 */
export const DELETE_TASK_TYPES = "DELETE_TASK_TYPES"
export const DELETE_TASK_TYPES_FAIL = "DELETE_TASK_TYPES_FAIL"
export const DELETE_TASK_TYPES_SUCCESS = "DELETE_TASK_TYPES_SUCCESS"

/***TaskTypes Parent Options */
export const FETCH_PARENT_TASKTYPES = 'FETCH_PARENT_TASKTYPES';
export const FETCH_PARENT_TASKTYPES_SUCCESS = 'FETCH_PARENT_TASKTYPES_SUCCESS';
export const FETCH_PARENT_TASKTYPES_FAILURE = 'FETCH_PARENT_TASKTYPES_FAILURE';

/***Task Type Config List */
export const GET_TASKTYPE_CONFIG_LIST = 'GET_TASKTYPE_CONFIG_LIST';
export const GET_TASKTYPE_CONFIG_LIST_SUCCESS = 'GET_TASKTYPE_CONFIG_LIST_SUCCESS';
export const GET_TASKTYPE_CONFIG_LIST_FAIL = 'GET_TASKTYPE_CONFIG_LIST_FAIL';

/***Add Task Type Config Items */
export const ADD_TASK_TYPE_CONFIG = "ADD_TASK_TYPE_CONFIG"
export const ADD_TASK_TYPE_CONFIG_SUCCESS = "ADD_TASK_TYPE_CONFIG_SUCCESS"
export const ADD_TASK_TYPE_CONFIG_FAIL = "ADD_TASK_TYPE_CONFIG_FAIL"

/***Update Task Type Config Items */
export const UPDATE_TASK_TYPE_CONFIG = "UPDATE_TASK_TYPE_CONFIG"
export const UPDATE_TASK_TYPE_CONFIG_SUCCESS = "UPDATE_TASK_TYPE_CONFIG_SUCCESS"
export const UPDATE_TASK_TYPE_CONFIG_FAIL = "UPDATE_TASK_TYPE_CONFIG_FAIL"

/***Delete Task Type Field item */
export const DELETE_TASK_TYPE_CONFIG = "DELETE_TASK_TYPE_CONFIG"
export const DELETE_TASK_TYPE_CONFIG_SUCCESS = "DELETE_TASK_TYPE_CONFIG_SUCCESS"
export const DELETE_TASK_TYPE_CONFIG_FAIL = "DELETE_TASK_TYPE_CONFIG_FAIL"
