import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { toast } from "react-toastify";

//Account Redux states
import { REGISTER_USER,ACTIVATE_ACCOUNT_REQUEST } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed,activateAccountError,activateAccountSuccess } from "./actions"

import {
companyRegisterAPI,
validateAccountAPI
} from "helpers/fakebackend_helper"


function* registerUser({ payload: { user } }) {debugger;
  try{
    const response = yield call(companyRegisterAPI, user);
    if (response.IsError){
      yield put(registerUserFailed(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }else{
      yield put(registerUserSuccessful(response.Data))
      toast.success("Company Registered Successfully", { autoClose: 2000 }); 
    }
  }
  catch(error){
    yield put(registerUserFailed(error));
    toast.error("Company Registration Failed", { autoClose: 2000 });
  }
}

function* validateAccount({ payload: { token } }) {debugger;
  try {
    const response = yield call(validateAccountAPI, token);
    if (response.IsError) {
      yield put(activateAccountError(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    } else {
      yield put(activateAccountSuccess());
      //toast.success("Account Activated Successfully", { autoClose: 2000 });
    }
  } catch (error) {
    yield put(activateAccountError(error));
    toast.error("Account Activation Failed", { autoClose: 2000 });
  }
}



export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(ACTIVATE_ACCOUNT_REQUEST,validateAccount)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
