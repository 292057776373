import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { toast } from "react-toastify";

// Login Redux States
import { RESET_PASSWORD } from "./actionTypes"
import { 
   resetPasswordError,resetPasswordSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  requestResetPasswordAPI
} from "../../../helpers/fakebackend_helper"

function* resetPassword({ payload }) {
  try {
    const response = yield call(requestResetPasswordAPI, payload);
    if (!response.IsError) {
      yield put(resetPasswordSuccess('Password reset successfully!'));
      toast.success("Password reset successfully", { autoClose: 2000 }); 
    } else {
      yield put(resetPasswordError(response.ErrorMessage));
      toast.error(response.ErrorMessage, { autoClose: 2000 });
    }
  } catch (error) {
    yield put(resetPasswordError(error));
    toast.error("Some thing Went Wrong", { autoClose: 2000 });
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD,resetPassword);
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)])
}

export default resetPasswordSaga
