import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import contacts from "./contacts/reducer";
import workflows from "./workflows/reducer";
import tasktypes from "./tasktypes/reducer";
import wf_taskConfiguration from "./taskconfiguration/reducer";
import ResetPassword from "./auth/resetpwd/reducer";
import passwordChangeReducer from "./auth/changepassword/reducer";
import dashboardReducer from "./dashboard/reducer";
import filemanager from "./filemanager/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  account,
  ForgetPassword,
  Profile,
  contacts,
  workflows,
  tasktypes,
  wf_taskConfiguration,
  ResetPassword,
  dashboardReducer,
  passwordChangeReducer,
  filemanager
});

export default rootReducer;
