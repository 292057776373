import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
  } from "./actionTypes"

export const resetPassword = (data) => ({
    type: RESET_PASSWORD,
    payload: data,
  });
  
  export const resetPasswordSuccess = (message) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
  });
  
  export const resetPasswordError = (error) => ({
    type: RESET_PASSWORD_ERROR,
    payload: error,
  });