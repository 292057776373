import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Input, Form, FormFeedback, Label, CardTitle, Modal, ModalHeader, ModalBody,UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableContainer from "../../../components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import DeleteModal from "components/Common/DeleteModal";
import {
  getTaskTypeConfigList,
  addTaskTypeConfig as onAddTaskTypeConfig,
  deleteTaskTypeField as onDeleteField,
  updateTaskTypeConfig as onUpdateTaskTypeConfig // Make sure this is imported
} from "store/tasktypes/actions";

const TasksTypesCreate = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { task_type_id,task_type_name } = location.state || {};
  
  const [taskTypeName, setTaskTypeName] = useState(() => localStorage.getItem("taskTypeName") || "");
  
  useEffect(() => {
    if (task_type_name) {
      setTaskTypeName(task_type_name);
      localStorage.setItem("taskTypeName", task_type_name);
    }
    
    if (task_type_id) {
      localStorage.setItem("taskTypeId", task_type_id);
      dispatch(getTaskTypeConfigList(task_type_id));
    } else {
      // If task_type_id doesn't exist in location.state, try to retrieve it from localStorage
      const storedTaskTypeId = localStorage.getItem("taskTypeId");
      const storedTaskTypeName = localStorage.getItem("taskTypeName");
      
      if (storedTaskTypeId && storedTaskTypeName) {
        setTaskTypeName(storedTaskTypeName);
        dispatch(getTaskTypeConfigList(storedTaskTypeId));
      }
    }
  }, [dispatch, task_type_id, task_type_name]);
  

  const { tasktypes_configlist, error } = useSelector((state) => ({
    tasktypes_configlist: state.tasktypes.tasktypes_configlist,
    error: state.tasktypes.error,
  }));

  useEffect(() => {
    document.title = "Task Type Fields";
  }, []);

  const [configModal, setConfigModal] = useState(false);
  const [isConfigEdit, setIsConfigEdit] = useState(false);
  const [selectedField, setSelectedTaskTypeField] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleConfigModal = () => setConfigModal(!configModal);

  const handleConfigClicks = () => {
    setIsConfigEdit(false);
    setSelectedTaskTypeField(null); // Clear the selected field
    modalFormValidation.resetForm(); // Reset the form fields
    toggleConfigModal();
  };

  const onClickDelete = (selectedField) => {
    setSelectedTaskTypeField(selectedField);
    setDeleteModal(true);
  };

  const handleDeleteTask = () => {
    if (selectedField && selectedField.field_id) {
      dispatch(onDeleteField(selectedField.field_id));
    }
    setDeleteModal(false);
  };

  const handleEditConfigClick = (selectedField) => {
    console.log('Selected Field:', selectedField);
    setSelectedTaskTypeField({
      field_id: selectedField.field_id,
      task_type_id: selectedField.task_type_id,
      field_label: selectedField.field_label,
      description: selectedField.description,
      field_datatype: selectedField.field_datatype,
      default_value: selectedField.default_value,
      required: selectedField.required,
    });
    setIsConfigEdit(true);
    toggleConfigModal();
  }

  const modalFormValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      field_label: (selectedField && selectedField.field_label) || "",
      description: (selectedField && selectedField.description) || "",
      field_datatype: (selectedField && selectedField.field_datatype) || "",
      default_value: (selectedField && selectedField.default_value) || "",
      required: (selectedField && selectedField.required) || "",
    },
    validationSchema: Yup.object({
      field_label: Yup.string().required("Please Enter Label"),
      description: Yup.string().required("Please Enter Description"),
      field_datatype: Yup.string().required("Please Enter Data Type"),
      default_value: Yup.mixed().test(
        'validate-default-value',
        'Invalid default value',
        function(value) {
          const { field_datatype } = this.parent;
          if (field_datatype === 'string') {
            return typeof value === 'string' && value !== '';
          } else if (field_datatype === 'boolean') {
            return value === 'true' || value === 'false';
          } else if (field_datatype === 'int') {
            return Number.isInteger(Number(value));
          }
          return true;
        }
      ),
      required: Yup.string().required("Please Select Required Status")
    }),
    onSubmit: (configvalues) => {
      if (isConfigEdit) {
        console.log('Selected Field:', configvalues);
        const updateConfigItem = {
          field_id: selectedField.field_id,
          task_type_id: selectedField.task_type_id,
          field_label: configvalues.field_label,
          description: configvalues.description,
          field_datatype: configvalues.field_datatype,
          default_value: configvalues.default_value,
          required: configvalues.required
        };
        dispatch(onUpdateTaskTypeConfig(updateConfigItem));
        modalFormValidation.resetForm();
      } else {
        const newConfigItem = {
          task_type_id: task_type_id,
          field_label: configvalues.field_label,
          description: configvalues.description,
          field_datatype: configvalues.field_datatype,
          default_value: configvalues.default_value,
          required: configvalues.required
        };
        dispatch(onAddTaskTypeConfig(newConfigItem));
        modalFormValidation.resetForm();
      }
      toggleConfigModal();
    }
  });

  const columns = useMemo(
    () => [
      {
        header: "#",
        cell: (info) => {
          return info.row.index + 1;
        },
        //accessorKey: "field_id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Label",
        accessorKey: "field_label",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => (
          <div style={{ width: "300px", whiteSpace: "wrap" }}>
            {info.getValue()}
          </div>
        ),
      },
      {
        header: "DataType",
        accessorKey: "field_datatype",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Default Value",
        accessorKey: "default_value",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Is Required",
        accessorKey: "required",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: 'Action',
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={(e) => {
                e.preventDefault();
                const configData = cellProps.row.original;
                handleEditConfigClick(configData);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <UncontrolledTooltip placement="top" target="edittooltip">
            Edit Field
           </UncontrolledTooltip>
            <Link
              to="#"
              className="text-danger"
              onClick={(e) => {
                e.preventDefault();
                const configData = cellProps.row.original;
                onClickDelete(configData);
              }}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
            <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete Field
           </UncontrolledTooltip>
          </div>
        )
      }
    ],
    []
  );

  const renderDefaultValueInput = () => {
    const { field_datatype, default_value } = modalFormValidation.values;
    if (!field_datatype) {
      return null; // If field_datatype is not selected, don't show anything
    }
    if (field_datatype === "string") {
      return (
        <div>
        <Label>Default Value</Label>
        <Input
          name="default_value"
          type="text"
          placeholder="Insert Default Value"
          onChange={modalFormValidation.handleChange}
          onBlur={modalFormValidation.handleBlur}
          value={default_value || ""}
          invalid={modalFormValidation.touched.default_value && modalFormValidation.errors.default_value ? true : false}
        />
        </div>
      );
    } else if (field_datatype === "boolean") {
      return (
        <div>
        <Label>Default Value</Label>
        <Input
          name="default_value"
          type="select"
          onChange={modalFormValidation.handleChange}
          onBlur={modalFormValidation.handleBlur}
          value={default_value || ""}
          invalid={modalFormValidation.touched.default_value && modalFormValidation.errors.default_value ? true : false}
        >
          <option value="">Select...</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </Input>
        </div>
      );
    } else if (field_datatype === "int") {
      return (
        <div>
        <Label>Default Value</Label>
        <Input
          name="default_value"
          type="number"
          placeholder="Insert Integer Default Value"
          onChange={modalFormValidation.handleChange}
          onBlur={modalFormValidation.handleBlur}
          value={default_value || ""}
          invalid={modalFormValidation.touched.default_value && modalFormValidation.errors.default_value ? true : false}
        />
        </div>
      );
    }
  
    return null; // Return nothing if no datatype is selected yet
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
        text="Are you sure you want to delete this field?"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="TaskTypes" breadcrumbItem="Task Type Fields" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>Task Type - {taskTypeName}</CardTitle>
                  <TableContainer
                    columns={columns}
                    data={tasktypes_configlist || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search..."
                    isCustomPageSize={true}
                    isAddButton={true}
                    handleUserClick={handleConfigClicks}
                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                    buttonName="New Field"
                    tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                    pagination="pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Modal for Configuration Form */}
          <Modal isOpen={configModal} toggle={toggleConfigModal}>
            <ModalHeader toggle={toggleConfigModal} tag="h4">
              {!!isConfigEdit ? "Edit Configuration Item" : "Add Configuration Item"}
            </ModalHeader>
            <ModalBody>
              <Form id="tasktype-configuration-form" onSubmit={modalFormValidation.handleSubmit}>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Label</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        name="field_label"
                        type="text"
                        placeholder="Insert Label"
                        onChange={modalFormValidation.handleChange}
                        onBlur={modalFormValidation.handleBlur}
                        value={modalFormValidation.values.field_label || ""}
                        invalid={modalFormValidation.touched.field_label && modalFormValidation.errors.field_label ? true : false}
                      />
                      {modalFormValidation.touched.field_label && modalFormValidation.errors.field_label ? (
                        <FormFeedback type="invalid">{modalFormValidation.errors.field_label}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Description</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        name="description"
                        type="text"
                        placeholder="Insert Description"
                        onChange={modalFormValidation.handleChange}
                        onBlur={modalFormValidation.handleBlur}
                        value={modalFormValidation.values.description || ""}
                        invalid={modalFormValidation.touched.description && modalFormValidation.errors.description ? true : false}
                      />
                      {modalFormValidation.touched.description && modalFormValidation.errors.description ? (
                        <FormFeedback type="invalid">{modalFormValidation.errors.description}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label for="field_datatype">DataType</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        id="field_datatype"
                        name="field_datatype"
                        type="select"
                        onChange={modalFormValidation.handleChange}
                        onBlur={modalFormValidation.handleBlur}
                        value={modalFormValidation.values.field_datatype || ""}
                        invalid={modalFormValidation.touched.field_datatype && modalFormValidation.errors.field_datatype ? true : false}
                      >
                        <option value="">Select...</option>
                        <option value="string">String</option>
                        <option value="boolean">Boolean</option>
                        <option value="int">Int</option>
                      </Input>
                      {modalFormValidation.touched.field_datatype && modalFormValidation.errors.field_datatype ? (
                        <FormFeedback type="invalid">{modalFormValidation.errors.field_datatype}</FormFeedback>
                      ) : null}
                    </div>

                    {/* <div className="mb-3">
                      <Label>Default Value</Label>
                      <Input
                        name="default_value"
                        placeholder="Insert Default Value"
                        type="text"
                        onChange={modalFormValidation.handleChange}
                        onBlur={modalFormValidation.handleBlur}
                        value={modalFormValidation.values.default_value || ""}
                        invalid={modalFormValidation.touched.default_value && modalFormValidation.errors.default_value ? true : false}
                      />
                      {modalFormValidation.touched.default_value && modalFormValidation.errors.default_value ? (
                        <FormFeedback type="invalid">{modalFormValidation.errors.default_value}</FormFeedback>
                      ) : null}
                    </div> */}
                    <div className="mb-3">
                      {/* <Label>Default Value</Label> */}
                      {renderDefaultValueInput()}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="required">Is Required?</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        name="required"
                        type="select"
                        onChange={modalFormValidation.handleChange}
                        onBlur={modalFormValidation.handleBlur}
                        value={modalFormValidation.values.required || ""}
                        invalid={modalFormValidation.touched.required && modalFormValidation.errors.required ? true : false}
                      >
                        <option value="">Select...</option>
                        <option value="Required">Yes</option>
                        <option value="Optional">No</option>
                      </Input>
                      {modalFormValidation.touched.required && modalFormValidation.errors.required ? (
                        <FormFeedback type="invalid">{modalFormValidation.errors.required}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success" type="submit" className="save-user">Save</Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default TasksTypesCreate;
