import axios from 'axios';
const API_URL = process.env.REACT_APP_APIKEY;

const fetch_custom_filters_with_options = async () => {
    try {
        const response = await axios.get(`${API_URL}/search-attributes/get_attributes_with_options`, {
            headers: { 'Content-Type': 'application/json' }
        });
        if (response.status === 200) {
            return response.data;  // Assuming this returns an array of filters
        } else {
            throw new Error(`Unexpected response status: ${response.status}`);
        }
    } catch (error) {
        console.error("Error fetching filters", error);
        throw error;
    }
};

export default fetch_custom_filters_with_options;
