import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { logoutUser } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useOktaAuth } from '@okta/okta-react';
import { OktaAuth } from "@okta/okta-auth-js";
import oktaConfig from '../../oktaConfig';

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        if (authState?.isAuthenticated) {
          // Perform Okta logout
          console.log(oktaConfig);
          const oktaAuth = new OktaAuth({ ...oktaConfig });
          // Await the Okta sign-out process
          await oktaAuth.signOut();
        }
        // Dispatch logout action to clear user data from your app
        dispatch(logoutUser(history));

        // Optionally navigate to a post-logout page (e.g., login page)
        history('/login');
      } catch (error) {
        console.error("Error during Okta logout:", error);
      }
    };

    handleLogout();
  }, [authState,dispatch, history]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);