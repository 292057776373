import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'; // Import DocViewer directly
import fetch_pdf_file from '../../helpers/fileservice'; // Adjust this import to match your structure
import { Card, CardBody, Container, Button } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

const secretKey = process.env.REACT_APP_SECRETKEY;

const TextFileViewerPage = () => {
    const [fileContent, setFileContent] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Get bucket and object key from URL query parameters
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('data');

    const decryptQueryString = (encryptedData) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            throw new Error('Failed to decrypt data');
        }
    };

    useEffect(() => {
        let blobUrl = null;
        if (encryptedData) {
            try {
                const { bucket, objectKey } = decryptQueryString(encryptedData);
                const fetchDocument = async () => {
                    debugger;
                    try {
                        const response = await fetch_pdf_file(bucket, objectKey);
                        //const blob = await response.blob(); // Ensure you're getting a Blob object
                        blobUrl = URL.createObjectURL(response);

                        // Set up content for DocViewer
                        setFileContent([{ uri: blobUrl, fileType: 'text/plain', fileName: objectKey.split('/').pop() }]);
                        setLoading(false);
                    } catch (error) {
                        console.error('Error fetching document:', error);
                        setError('Failed to load document');
                        setLoading(false);
                    }
                };

                fetchDocument();
            } catch (err) {
                console.error('Decryption error:', err);
                setError('Invalid or corrupted data');
                setLoading(false);
            }
        } else {
            setError('No data provided');
            setLoading(false);
        }
    }, [encryptedData]);
    // Function to handle file download
    const handleDownload = () => {
        if (fileContent.length > 0) {
            const { uri, fileName } = fileContent[0]; // Access the first object in the array
            const link = document.createElement('a');
            link.href = uri;
            link.download = fileName || 'downloaded_file'; // Fallback to default name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('No file available to download');
        }
    };

    const styles = {
        // container: {
        //     height: '100vh',
        //     width: '100%',
        //     paddingTop: '100px',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     flexDirection: 'column',
        //     backgroundColor: '#f9f9f9',
        //     fontFamily: 'Arial, sans-serif',
        // },
        documentContainer: {
            width: '80%',
            minHeight: '80vh',
            overflowY: 'auto',
            //border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            padding: '20px',
            margin: 'auto'
        },       
        error: {
            color: 'red',
        },
    };

    return (
        //         <div style={styles.container}>
        //             {loading && <p>Loading document...</p>}
        //             {error && <p style={styles.error}>{error}</p>}
        //             {fileContent.length > 0 && (
        //                 <div style={styles.documentContainer}>
        //                     <h2 style={styles.header}>Text Document Viewer</h2>
        //                     <DocViewer documents={fileContent} />
        //                 </div>
        //             )}
        //         </div>
        //     );
        // };

        // const styles = {
        //     container: {
        //         height: '100vh',
        //         width: '100%',
        //         paddingTop: '100px',
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //         flexDirection: 'column',
        //         backgroundColor: '#f9f9f9',
        //         fontFamily: 'Arial, sans-serif',
        //     },
        //     documentContainer: {
        //         width: '80%',
        //         maxHeight: '80vh',
        //         overflowY: 'auto',
        //         border: '1px solid #ccc',
        //         borderRadius: '8px',
        //         backgroundColor: '#fff',
        //         boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        //         padding: '20px',
        //     },
        //     header: {
        //         textAlign: 'center',
        //         color: '#333',
        //         marginBottom: '20px',
        //     },
        //     error: {
        //         color: 'red',
        //     },
        // };
        //new code with styling 
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="File List" breadcrumbItem="File Detail" link="/fileslist" />
                    <Card>
                    <CardBody className="border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1">{fileContent.length > 0 ? fileContent[0].fileName : 'Document'}</h5>
                                <div className="flex-shrink-0">
                                    <Button color="primary" onClick={handleDownload} className="btn me-1 mt-2">
                                        Download
                                    </Button>                                   
                                </div>
                            </div>
                        </CardBody>                       
                        <CardBody>
                            {loading && <p>Loading document...</p>}
                            {error && <p style={styles.error}>{error}</p>}
                            {fileContent.length > 0 && (
                                <div style={styles.documentContainer}>                                    
                                    <DocViewer documents={fileContent} theme={{
                                        primary: "#5296d8",
                                        secondary: "#ffffff",
                                        tertiary: "#5296d899",
                                        textPrimary: "#ffffff",
                                        textSecondary: "#5296d8",
                                        textTertiary: "#00000099",
                                        disableThemeScrollbar: false,
                                    }} 
                                    pluginRenderers={DocViewerRenderers}/>
                                </div>
                            )}

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default TextFileViewerPage;
