import React, { useState, useRef, useCallback, useEffect,useMemo } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  BackgroundVariant,
  Panel,
  MiniMap,
  getIncomers,
  getOutgoers,
  getConnectedEdges,

} from 'reactflow';
import 'reactflow/dist/style.css';
import Sidebar_1 from './Sidebar_1';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import './index.css';
import {
  getWorkflowConfig as onGetWorkflowConfig,
  updateWorkflowConfig as onUpdateWorkflowConfig,
  deleteWorkflowNodeConfig as onDeleteWorkflowNodeConfig
} from '../../../store/workflows/actions';

import TaskConfiguration from './TaskConfiguration';
import CustomNode from './CustomNode';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from "react-toastify";

const initialNodes = [];

// let id = 0;
// const getId = () => `dndnode_${id++}`;

// Function to generate unique ID starting from the highest existing ID
// const getId = (nodes) => {
//   const ids = nodes.map(node => parseInt(node.id.replace('dndnode_', ''), 10)).filter(id => !isNaN(id));
//   const maxId = ids.length ? Math.max(...ids) : 0;
//   return `dndnode_${maxId + 1}`;
// };

const getTaskIndex = (nodes) => {
  const ids = nodes.map(node => parseInt(node.id.replace('dndnode_', ''), 10)).filter(id => !isNaN(id));
  const maxId = ids.length ? Math.max(...ids) : 0;
  return maxId + 1;
};

const DnDFlow = ({ isEditMode, workflowId }) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  //edit hooks
  const [isEditing, setIsEditing] = useState(false)
  const [editValue, setEditValue] = useState(nodes.data)
  const [id, setId] = useState();
  const [TaskTypeId, setTaskTypeId] = useState()
  const [TaskId, setTaskId] = useState()
  const [SelectedNode, setSelectedNode] = useState()  
 console.log(reactFlowInstance?.getNodes().filter((node) => node.selected))
  //function for edit
  const onNodeClick = (e, val) => {
    debugger;
    const task_id= val.data.task_id;
    const task_type_id = val.data.task_type_id;
    // const nodeLabel = val.data.label;
    // fetchTaskConfiguration(taskId);
    // dispatch(onGetTaskConfiguration(taskId));
    setEditValue(val.data.label)
    setId(val.id)    
    setIsEditing(true)
    setTaskTypeId(task_type_id)
    setTaskId(task_id)
    setSelectedNode(val)
    console.log(val)   
  }
  // const handleEdit = () => {
  //   const res = nodes.map((item) => {
  //     if (item.id === configId) {
  //       item.data = {
  //         ...item.data,
  //         // label: editValue,
  //         config: formValues,         
  //       };
  //     }
  //     return item;
  //   });
  //   setNodes(res);
  //   // setEditValue('');
  //   setIsEditing(false);
  // };
  const handleEdit = (success,newLabel) => {    
    const updatedNodes = nodes.map(node => {
      if (node.data.task_id === TaskId) {
        console.log(`Updating node label from ${node.data.label} to ${newLabel}`);
        return {
          ...node,
          data: {
            ...node.data,
            label: newLabel,            
            isvalid: true 
          }
        };
      }
      console.log(newLabel)
      return node;
    });
    setEditValue(newLabel);  
    setNodes(updatedNodes);
    
    console.log("Updated nodesList:", updatedNodes)    
    setTimeout(() => {
      onSave(updatedNodes);
    }, 0); 
  };

  const onConnect = useCallback(
    (params) => {
      console.log('Connecting:', params);
      setEdges((eds) => addEdge(params, eds));
    },
    [],
  );

  const dispatch = useDispatch();

  // Selector to get workflows and loading state
  const WorkflowProperties = createSelector(
    (state) => state.workflows,
    (Workflows) => ({
      workflows: Workflows?.workflowConfig,
      loading: Workflows?.loading
    })
  );


  const { workflows } = useSelector(WorkflowProperties);

  const flowKey = 'workflows';
  
  // const duplicateSelectedNode = useCallback(() => {    
  //   if (reactFlowInstance) {
  //     const selectedNodes = reactFlowInstance.getNodes().filter((node) => node.selected);  
  //     if (selectedNodes.length > 0) {
  //       const offsetX = 100; // X-axis offset for duplicate nodes
  //       const offsetY = 100; // Y-axis offset for duplicate nodes       
  //       const newNodes = selectedNodes.map(node => ({
  //         ...node,
  //         id: getId(nodes), // Ensure a unique ID
  //         data: {
  //           ...node.data,
  //           task_id: uuidv4(), // Ensure unique task ID
  //           task_index:getTaskIndex(nodes),
  //           isvalid:false
  //         },
  //         position: {
  //           x: node.position.x + offsetX, // Offset position
  //           y: node.position.y + offsetY
  //         },
         
  //       }));  
  //       //setNodes((nds) => [...nds, ...newNodes]);
  //       setNodes((nodes) => [
  //         ...nodes.map((node) => ({ ...node, selected: false })),
  //         ...newNodes,
  //       ]);
  //       setTimeout(() => {
  //         onSave();
  //       }, 0);
  //     }
  //   }
  // },
  // [reactFlowInstance, nodes],);
  
  const duplicateSelectedNode = useCallback(() => {
    if (reactFlowInstance) {
      const selectedNodes = reactFlowInstance.getNodes().filter((node) => node.selected);  
      if (selectedNodes.length > 0) {
        const offsetX = 100; // X-axis offset for duplicate nodes
        const offsetY = 100; // Y-axis offset for duplicate nodes
         
        const newNodes = selectedNodes.map(node => {
          const newId = uuidv4(); // Generate a single unique UUID
          return {
            ...node,
            id: newId, // Use the UUID as the ID
            data: {
              ...node.data,
              task_id: newId, // Use the same UUID for task_id
              task_index: getTaskIndex(nodes),
              isvalid: false
            },
            position: {
              x: node.position.x + offsetX, // Offset position
              y: node.position.y + offsetY
            },
          };
        });
  
        setNodes((nodes) => [
          ...nodes.map((node) => ({ ...node, selected: false })),
          ...newNodes,
        ]);
  
        setTimeout(() => {
          onSave();
        }, 0);
      }
    }
  }, [reactFlowInstance, nodes]);
  
  
  const onSave = useCallback(() => {
    if (reactFlowInstance) {      
      const flow = reactFlowInstance.toObject(); //need to send only edges
      console.log(flow)
      const payload = {
        config_id: workflowId,
        config_details: flow,
        updated_by: "",  // Add the relevant updated_by value here
      };
      dispatch(onUpdateWorkflowConfig(payload));     
    }
  }, [reactFlowInstance, dispatch, workflowId]); 

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');      
      const { nodeType, nodeName, task_type_id } = JSON.parse(type);

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const task_id = uuidv4(); //Generate new id
      const newNode = {
        id: task_id,
        type:nodeType,
        position,
        data: { label: `${nodeName}`, task_type_id: task_type_id,task_id:task_id,task_index:getTaskIndex(nodes),isvalid:false},
      };
      setNodes((nds) => nds.concat(newNode));
      // setNodes((nds) => [...nds, newNode]);      
    },
    [reactFlowInstance, nodes],
  );
  const handleCancel = () => {
    setIsEditing(false)
    // setEditValue('')
  }

  // For editing work flow 

  useEffect(() => {
    if (isEditMode && workflowId) {      
      //Fetch the workflow data using the workflowId
      // Assuming there's a function like fetchWorkflow to get the workflow data
      dispatch(onGetWorkflowConfig(workflowId));
    }
  }, [isEditMode, workflowId, dispatch]);


  useEffect(() => {
    if (workflows) {
      try {
        const parsedFlow = workflows; // Assuming workflows is a JSON string 
        console.log(parsedFlow)     
        if (parsedFlow) {
          setNodes(parsedFlow.nodes || []);
          setEdges(parsedFlow.edges || []);
          // const { x = 0, y = 0, zoom = 1 } = parsedFlow.viewport || {};
          // setViewport({ x, y, zoom });
        }
      } catch (error) {
        console.error("Error parsing workflow data:", error);
      }
    }
  }, [workflows, setNodes, setEdges]);
  const onDelete = useCallback((id, task_id) => {
    console.log(id,workflowId,task_id)
    const deleteConfigDetails = {
      config_id: Number(workflowId), 
      task_id: task_id 
    };
    // Convert the payload to JSON format if necessary before dispatching
     //const payload = JSON.stringify(configDetails);
    dispatch(onDeleteWorkflowNodeConfig(deleteConfigDetails))
    setNodes((nds) => nds.filter(node => node.id !== id));
    setEdges((eds) => eds.filter(edge => edge.source !== id && edge.target !== id));
    setTimeout(() => {
      onSave();
    }, 0);
  }, [dispatch, setNodes, setEdges, onSave]);
  // new implementation for node duplication
  
  // const nodeTypes = useMemo(() => ({customNode:CustomNode }), []);
  const nodeTypes = useMemo(() => ({ customNode: (props) => <CustomNode {...props} onDelete={onDelete} onDuplicate={duplicateSelectedNode}/>  }), [onDelete]);
  // const onDelete =(id) =>{
  //   setNodes((prevValue)=>{
  //      return prevValue.filter(index =>{
  //       index !== id
  //      })
  //   })
  // }
  // const onDelete = (id) => {
  //   setNodes((nds) => nds.filter(node => node.id !== id));
  // }
  // const onNodesDelete = useCallback((deletedNodes) => {
  //   setNodes((nds) => nds.filter(node => !deletedNodes.some(delNode => delNode.id === node.id)));
  // }, [setNodes]);

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge),
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            })),
          );

          return [...remainingEdges, ...createdEdges];
        }, edges),
      );
    },
    [nodes, edges],
  ); 

  return (
    <div className="providerflow" >
      <div className="dndflow" style={{ width: "100%", height: '100vh' }} >
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ width: isEditing ? '70%' : '100%', transition: 'width 0.3s' }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodeClick={(e, val) => onNodeClick(e, val)}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              nodeTypes={nodeTypes}
              onNodesDelete={onNodesDelete}
              // onDelete={onDelete}
              // onNodesDelete={onNodesDelete}
            >
              <Controls />
              <MiniMap />
              <Background variant={BackgroundVariant.Dots} />
              <Panel position="top-right">
                <button onClick={onSave}>save</button>
                {/* <button onClick={onRestore}>restore</button>         */}
                {/* <button onClick={duplicateSelectedNode}>Duplicate Selected Node</button> */}
              </Panel>
            </ReactFlow>
          </div>
          <Sidebar_1 />
        </ReactFlowProvider>
        <ToastContainer />
      </div>
      {isEditing && (<TaskConfiguration 
        taskId={TaskId}
        task_type_id={TaskTypeId}
        onConfigUpdate={handleEdit}
        onCancel={handleCancel}
        editId={workflowId} 
        nodeLabel={editValue}
        nodeProperty={SelectedNode}        
        />        
      )}
      {isEditing && (<div className="rightbar-overlay"></div>)}
    </div>
  );
};

export default DnDFlow;
