import {
    UPDATE_WORKFLOW_TASK_CONFIG,
    UPDATE_WORKFLOW_TASK_CONFIG_SUCCESS,
    UPDATE_WORKFLOW_TASK_CONFIG_FAIL,
    GET_WORKFLOW_TASK_CONFIG,
    GET_WORKFLOW_TASK_CONFIG_SUCCESS,
    GET_WORKFLOW_TASK_CONFIG_FAIL
} from "./actionTypes"

export const UpdateWorkflowTaskConfig = task_config => ({
    type: UPDATE_WORKFLOW_TASK_CONFIG,
    payload: task_config,
  })
  
export const UpdateWorkflowTaskConfigSuccess = task_config => ({
    type: UPDATE_WORKFLOW_TASK_CONFIG_SUCCESS,
    payload: task_config,
  })
  
export const UpdateWorkflowTaskConfigFail = error => ({
    type: UPDATE_WORKFLOW_TASK_CONFIG_FAIL,
    payload: error,
  })

export const GetWorkflowTaskConfigList = (workflow_id,task_id,tasktype_id) => ({
    type: GET_WORKFLOW_TASK_CONFIG,
    payload: workflow_id,task_id,tasktype_id
});

export const GetWorkflowTaskConfigListSuccess = (TaskConfigList) => ({
    type: GET_WORKFLOW_TASK_CONFIG_SUCCESS,
    payload: TaskConfigList,
});

export const GetWorkflowTaskConfigListFail = (error) => ({
    type: GET_WORKFLOW_TASK_CONFIG_FAIL,
    payload: error,
});  