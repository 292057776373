// Get Files List
export const GET_FILES = "GET_FILES"
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS"
export const GET_FILES_FAIL = "GET_FILES_FAIL"

// View File
export const VIEW_FILE = "VIEW_FILE"
export const VIEW_FILE_SUCCESS = "VIEW_FILE_SUCCESS"
export const VIEW_FILE_FAIL = "VIEW_FILE_FAIL"

// Get Filtered Files
export const GET_FILTERED_FILES = "GET_FILTERED_FILES"
export const GET_FILTERED_FILES_SUCCESS = "GET_FILTERED_FILES_SUCCESS"
export const GET_FILTERED_FILES_FAIL = "GET_FILTERED_FILES_FAIL"

// Get Filter List in to the Admin module
export const GET_CUSTOM_FILTERS_LIST = "GET_CUSTOM_FILTERS_LIST"
export const GET_CUSTOM_FILTERS_LIST_SUCCESS = "GET_CUSTOM_FILTERS_LIST_SUCCESS"
export const GET_CUSTOM_FILTERS_LIST_FAIL = "GET_CUSTOM_FILTERS_LIST_FAIL"

// Add filter 
export const ADD_CUSTOM_FILTER = "ADD_CUSTOM_FILTER"
export const ADD_CUSTOM_FILTER_SUCCESS = "ADD_CUSTOM_FILTER_SUCCESS"
export const ADD_CUSTOM_FILTER_FAIL = "ADD_CUSTOM_FILTER_FAIL"

export const UPDATE_CUSTOM_FILTER = "UPDATE_CUSTOM_FILTER"
export const UPDATE_CUSTOM_FILTER_SUCCESS = "UPDATE_CUSTOM_FILTER_SUCCESS"
export const UPDATE_CUSTOM_FILTER_FAIL = "UPDATE_CUSTOM_FILTER_FAIL"

export const DELETE_CUSTOM_FILTER = "DELETE_CUSTOM_FILTER"
export const DELETE_CUSTOM_FILTER_SUCCESS = "DELETE_CUSTOM_FILTER_SUCCESS"
export const DELETE_CUSTOM_FILTER_FAIL = "DELETE_CUSTOM_FILTER_FAIL"

// Add filter meta data 
export const GET_CUSTOM_FILTER_META_DATA = "ADD_CUSTOM_FILTER_META_DATA"
export const GET_CUSTOM_FILTER_META_DATA_SUCCESS = "ADD_CUSTOM_FILTER_META_DATA_SUCCESS"
export const GET_CUSTOM_FILTER_META_DATA_FAIL = "ADD_CUSTOM_FILTER_META_DATA_FAIL"

export const UPDATE_CUSTOM_FILTER_META_DATA = "UPDATE_CUSTOM_FILTER_META_DATA"
export const UPDATE_CUSTOM_FILTER_META_DATA_SUCCESS = "UPDATE_CUSTOM_FILTER_META_DATA_SUCCESS"
export const UPDATE_CUSTOM_FILTER_META_DATA_FAIL = "UPDATE_CUSTOM_FILTER_META_DATA_FAIL"