import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../store/dashboard/actions";
import { Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import avatar1 from "../../assets/images/users/defaultprofile.png";
import { getLoggedInUser } from "../../helpers/fakebackend_helper";
import profileImg from "../../assets/images/profile-img.png"

const CardWorkflow = () => {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const dispatch = useDispatch();
  const { dashboardData, loading, error } = useSelector((state) => state.dashboardReducer);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getLoggedInUser();
        setLoggedInUser(user);
        console.log("Logged In User:", user); // Check loggedInUser data here
      } catch (error) {
        console.error("Error fetching logged in user", error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (loggedInUser && loggedInUser.company_id && loggedInUser.user_id) {
      console.log("Dispatching fetchDashboardData with:", {
        company_id: loggedInUser.company_id,
        user_id: loggedInUser.user_id,
      });
      const data = {
        company_id: loggedInUser.company_id,
        user_id: loggedInUser.user_id,
      };
      dispatch(fetchDashboardData(data));
    }
  }, [loggedInUser, dispatch]);

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <img src={avatar1} alt="" className="avatar-md rounded-circle img-thumbnail" />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome to DataOS </p>
                        <h5 className="mb-1">{dashboardData?.user_info.first_name} {dashboardData?.user_info.last_name}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="6">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total Workflows
                          </p>
                          <h5 className="mb-0">{dashboardData?.workflow_counts.total_workflows}</h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Active Workflows
                          </p>
                          <h5 className="mb-0">{dashboardData?.workflow_counts.active_workflows}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0">
                    <Dropdown
                      isOpen={settingsMenu}
                      toggle={() => {
                        setSettingsMenu(!settingsMenu);
                      }}
                      className="float-end"
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        <i className="bx bxs-cog align-middle me-1" /> Worflows
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {/* <DropdownItem href="/workflow">Add New Workflow</DropdownItem> */}
                        <DropdownItem href="/workflows-list">Workflow List</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
             
              {error && <p className="text-danger mt-2">Error: {error}</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
      <Col xl="4">
        <Card className="bg-primary-subtle">
          <div>
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>DataOS Dashboard</p>

                  <ul className="ps-3 mb-0">
                    <li className="py-2">{dashboardData?.workflow_counts.active_workflows} + Active Workflows</li>
                  </ul>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
        <Col xl="8">
          <Row>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-18">
                        <i className="bx bx-user-circle" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Total Users</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>
                      {dashboardData?.user_counts.total_users}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-success-subtle text-success font-size-18">
                        <i className="bx bx-user-check" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Active Users</h5>
                  </div>
                  <div className="text-muted mt-4"  >
                    <h4>
                      {dashboardData?.user_counts.active_users}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-danger-subtle text-danger font-size-18">
                        <i className="bx bx-user-x" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">InActive Users</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>
                      {dashboardData?.user_counts.inactive_users}
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CardWorkflow;
