import {
    GET_FILES,
    GET_FILES_SUCCESS,
    GET_FILES_FAIL,
    VIEW_FILE,
    VIEW_FILE_SUCCESS,
    VIEW_FILE_FAIL,
    GET_FILTERED_FILES,
    GET_FILTERED_FILES_SUCCESS,
    GET_FILTERED_FILES_FAIL,
    GET_CUSTOM_FILTERS_LIST, //get custom filters list
    GET_CUSTOM_FILTERS_LIST_SUCCESS,
    GET_CUSTOM_FILTERS_LIST_FAIL,
    ADD_CUSTOM_FILTER,
    ADD_CUSTOM_FILTER_SUCCESS,
    ADD_CUSTOM_FILTER_FAIL,
    UPDATE_CUSTOM_FILTER,
    UPDATE_CUSTOM_FILTER_SUCCESS,
    UPDATE_CUSTOM_FILTER_FAIL,
    DELETE_CUSTOM_FILTER,
    DELETE_CUSTOM_FILTER_SUCCESS,
    DELETE_CUSTOM_FILTER_FAIL,
    GET_CUSTOM_FILTER_META_DATA,
    GET_CUSTOM_FILTER_META_DATA_SUCCESS,
    GET_CUSTOM_FILTER_META_DATA_FAIL,
    UPDATE_CUSTOM_FILTER_META_DATA,    
    UPDATE_CUSTOM_FILTER_META_DATA_SUCCESS,
    UPDATE_CUSTOM_FILTER_META_DATA_FAIL,
} from "./actionTypes";

export const getFiles = (user_id) =>({
    type: GET_FILES,
    payload : user_id
})

export const getFilesSuccess = files =>({
    type: GET_FILES_SUCCESS,
    payload: files,
})

export const getFilesFail = error =>({
    type: GET_FILES_FAIL,
    error: error,
})

// Action to initiate the file view request
export const viewFileRequest = (bucketName, objectKey) => ({    
    type: VIEW_FILE,
    payload: { bucketName, objectKey },
});

// Action on successful file fetch
// export const viewFileSuccess = fileUrl => ({
//     type: VIEW_FILE_SUCCESS,
//     payload: fileUrl,
// });
export const viewFileSuccess = fileBlob => ({
    type: VIEW_FILE_SUCCESS,
    payload: fileBlob,
});

// Action on file fetch failure
export const viewFileFail = error => ({
    type: VIEW_FILE_FAIL,
    payload: error,
});

export const getFilteredFiles = payload =>({
    type: GET_FILTERED_FILES,
    payload: payload,
  })

  export const getFilteredFilesSuccess = filterDetails =>({
    type: GET_FILTERED_FILES_SUCCESS,
    payload: filterDetails,
  })

  export const getFilteredFilesFail = error =>({
    type: GET_FILTERED_FILES_FAIL,
    payload:error,
  })

//   get filterslist
export const getCustomFiltersList = () =>({
    type: GET_CUSTOM_FILTERS_LIST
})

export const getCustomFiltersListSuccess = customFiltersList =>({
    type: GET_CUSTOM_FILTERS_LIST_SUCCESS,
    payload: customFiltersList
})

export const getCustomFiltersListFail = error =>({
    type: GET_CUSTOM_FILTERS_LIST_FAIL,
    payload: error
})

export const addCustomFilter = customFilter => ({
    type: ADD_CUSTOM_FILTER,
    payload: customFilter,
  })
  
  export const addCustomFilterSuccess = customFilter => ({
    type: ADD_CUSTOM_FILTER_SUCCESS,
    payload: customFilter,
  })
  
  export const addCustomFilterFail = error => ({
    type: ADD_CUSTOM_FILTER_FAIL,
    payload: error,
  })
  
  export const updateCustomFilter = customFilter => ({
    type: UPDATE_CUSTOM_FILTER,
    payload: customFilter,
  })
  
  export const updateCustomFilterSuccess = customFilter => ({
    type: UPDATE_CUSTOM_FILTER_SUCCESS,
    payload: customFilter,
  })
  
  export const updateCustomFilterFail = error => ({
    type: UPDATE_CUSTOM_FILTER_FAIL,
    payload: error,
  })
  
  export const deleteCustomFilter = customFilter => ({
    type: DELETE_CUSTOM_FILTER,
    payload: customFilter,
  })
  
  export const deleteCustomFilterSuccess = customFilter => ({
    type: DELETE_CUSTOM_FILTER_SUCCESS,
    payload: customFilter,
  })
  
  export const deleteCustomFilterFail = error => ({
    type: DELETE_CUSTOM_FILTER_FAIL,
    payload: error,
  })

  
// update and saving custom filter metadata

  export const getCustomFilterMetaData = file_id => ({
    type: GET_CUSTOM_FILTER_META_DATA,
    payload:file_id
  })
 
  export const  getCustomFilterMetaDataSuccess = metadata => ({
    type: GET_CUSTOM_FILTER_META_DATA_SUCCESS,
    payload: metadata,
  })
  
  export const getCustomFilterMetaDataFail = error => ({
    type: GET_CUSTOM_FILTER_META_DATA_FAIL,
    payload: error,
  })


  export const updateCustomFilterMetaData = metadata =>({
    type:  UPDATE_CUSTOM_FILTER_META_DATA,
    payload: metadata,
  })

  export const updateCustomFilterMetaDataSuccess = metadata =>({
    type:  UPDATE_CUSTOM_FILTER_META_DATA_SUCCESS,
    payload: metadata,
  })

  export const updateCustomFilterMetaDataFail = error =>({
    type:  UPDATE_CUSTOM_FILTER_META_DATA_FAIL,
    payload:error,
  })

  
 