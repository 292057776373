import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
    UncontrolledTooltip
} from "reactstrap";
import TableContainer from "components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
    getCustomFiltersList as onGetCustomFiltersList,
    addCustomFilter as onAddCustomFilter,
    updateCustomFilter as onUpdateCustomFilter,
    deleteCustomFilter as onDeleteCustomFilter
} from "store/actions";
import { ToastContainer } from "react-toastify";


const FileManagerFiltersList = () => {
    document.title = "File Manager Filters List";
    const dispatch = useDispatch();
    const [filtersList, setFiltersList] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const filemanagerProperties = createSelector(
        (state) => state.filemanager,
        (filemanager) => ({
            loading: filemanager.loading,
            customFiltersList: filemanager.customFiltersList
        })
    );

    const { customFiltersList, loading } = useSelector(filemanagerProperties);

    useEffect(() => {
        if (!customFiltersList.length) {
            dispatch(onGetCustomFiltersList());
        }
    }, [dispatch, customFiltersList.length]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            attribute_id: filtersList?.attribute_id || "",
            attribute_key: filtersList?.attribute_key || "",
            attribute_name: filtersList?.attribute_name || "",
            description: filtersList?.description || "",
            attribute_type: filtersList?.attribute_type || "",
        },
        validationSchema: Yup.object({
            attribute_key: Yup.string().required("Please Enter Filter Name"),
            attribute_name: Yup.string().required("Please Enter Display Name"),
            description: Yup.string().required("Please Enter Filter Description"),
            attribute_type: Yup.string().required("Please Select Filter Type")
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateFilter = {
                    attribute_id: values.attribute_id,
                    attribute_key: values.attribute_key,
                    attribute_name: values.attribute_name,
                    description: values.description,
                    attribute_type: values?.attribute_type,
                };
                dispatch(onUpdateCustomFilter(updateFilter));
            } else {
                const newFilter = {
                    attribute_key: values.attribute_key,
                    attribute_name: values.attribute_name,
                    description: values.description,
                    attribute_type: values.attribute_type,
                };
                dispatch(onAddCustomFilter(newFilter));
            }
            validation.resetForm();
            toggle();
        },
    });

    const handleFilterClick = arg => {
        debugger;
        const filtersList = arg;
        setFiltersList(filtersList);
        setIsEdit(true);
        toggle();
    }

    const onClickDelete = (filtersList) => {
        setFiltersList(filtersList);
        setDeleteModal(true);
    };

    const handleDeleteFilterList = () => {
        if (filtersList && filtersList.attribute_id) {
            const filterListData = {
                attribute_id: filtersList.attribute_id,
            }
            dispatch(onDeleteCustomFilter(filterListData));
        }
        setDeleteModal(false);
    };

    const toggle = () => {
        setModal(!modal);
    };

    const handleFilterClicks = () => {
        setFiltersList("");
        setIsEdit(false);
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                header: "#",
                cell: (info) => {
                    return info.row.index + 1;
                },
                //accessorKey: "field_id",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: "Filter Name",
                accessorKey: "attribute_key",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Display Name",
                accessorKey: "attribute_name",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Description",
                accessorKey: "description",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Filter Type",
                accessorKey: "attribute_type",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Created On",
                accessorKey: "created_at",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Action",
                cell: (cellProps) => (
                    <div className="d-flex gap-3">
                        <Link
                            to="#"
                            className="text-success"
                            onClick={(e) => {
                                e.preventDefault();
                                const filterListData = cellProps.row.original;
                                handleFilterClick(filterListData)
                            }}
                        >
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        </Link>
                        <UncontrolledTooltip placement="top" target="edittooltip">
                            Edit Filter
                        </UncontrolledTooltip>                       
                        {cellProps.row.original.isdefault ? (
                        <span className="text-muted">
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip-disabled" />
                        </span>
                    ) : (
                        <Link
                            to="#"
                            className="text-danger"
                            onClick={() => {
                                const filterListData = cellProps.row.original;
                                onClickDelete(filterListData);
                            }}
                        >
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                        </Link>
                    )}

                    {/* Tooltip */}
                    <UncontrolledTooltip placement="top" target={cellProps.row.original.isdefault ? "deletetooltip-disabled" : "deletetooltip"}>
                        {cellProps.row.original.isdefault ? "Default Filter - Cannot Delete" : "Delete Custom Filter"}
                    </UncontrolledTooltip>
                    </div>
                ),
            },

        ], []
    )
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteFilterList}
                onCloseClick={() => setDeleteModal(false)}
                text="Are you sure you want to delete this filter details?"
            />
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="FileManager Filters List" breadcrumbItem="Filters List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={customFiltersList || []}
                                        isGlobalFilter={true}
                                        isPagination={true}
                                        SearchPlaceholder="Search..."
                                        isCustomPageSize={true}
                                        isAddButton={true}
                                        handleUserClick={handleFilterClicks}
                                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                        buttonName="Add Custom Filter"
                                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                        pagination="pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {isEdit ? "Edit Filter Details" : "Add Filter Details"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col xs={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="attribute_key">Filter Name</Label>
                                            <span style={{ color: "red" }}>*</span>
                                            <Input
                                                name="attribute_key"
                                                type="text"
                                                placeholder="Enter Filter Name..."
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.attribute_key || ""}
                                                invalid={
                                                    validation.touched.attribute_key && validation.errors.attribute_key
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.attribute_key && validation.errors.attribute_key ? (
                                                <FormFeedback type="invalid">{validation.errors.attribute_key}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="attribute_name">Display Name</Label>
                                            <span style={{ color: "red" }}>*</span>
                                            <Input
                                                name="attribute_name"
                                                type="text"
                                                placeholder="Enter Display Name..."
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.attribute_name || ""}
                                                invalid={
                                                    validation.touched.attribute_name && validation.errors.attribute_name
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.attribute_name && validation.errors.attribute_name ? (
                                                <FormFeedback type="invalid">{validation.errors.attribute_name}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Filter Description</Label>
                                            <span style={{ color: "red" }}>*</span>
                                            <Input
                                                name="description"
                                                placeholder="Enter Description"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ""}
                                                invalid={
                                                    validation.touched.description && validation.errors.description
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.description && validation.errors.description ? (
                                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="filter-type">Filter Type</Label>
                                            <span style={{ color: "red" }}>*</span>
                                            <select
                                                className={`form-select pageSize ${validation.touched.attribute_type && validation.errors.attribute_type ? "is-invalid" : ""
                                                    }`}
                                                id="attribute_type"
                                                name="attribute_type"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.attribute_type || ""}
                                            >
                                                <option value="">Select a Filter Type</option>
                                                <option value="Text">Text</option>
                                                <option value="Dropdown">Dropdown</option>
                                                <option value="Date">Date</option>
                                            </select>
                                            {validation.touched.attribute_type && validation.errors.attribute_type && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {validation.errors.attribute_type}
                                                </FormFeedback>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-success save-user">
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default FileManagerFiltersList