import {
    GET_TASKS_TYPES_SUCCESS,
    GET_TASKS_TYPES_FAIL,
    ADD_TASK_TYPES_FAIL,
    ADD_TASK_TYPES_SUCCESS,
    UPDATE_TASK_TYPES_FAIL,
    UPDATE_TASK_TYPES_SUCCESS,
    GET_TASKTYPE_CONFIG_LIST_SUCCESS,
    GET_TASKTYPE_CONFIG_LIST_FAIL,
    ADD_TASK_TYPE_CONFIG_SUCCESS,
    ADD_TASK_TYPE_CONFIG_FAIL,
    UPDATE_TASK_TYPE_CONFIG_FAIL,
    UPDATE_TASK_TYPE_CONFIG_SUCCESS,
    DELETE_TASK_TYPES_SUCCESS,
    DELETE_TASK_TYPES_FAIL,
    DELETE_TASK_TYPE_CONFIG_SUCCESS,
    DELETE_TASK_TYPE_CONFIG_FAIL
} from "./actionTypes"

const INIT_STATE = {
    tasktypes: [],
    tasktypes_configlist: [],
    error: null,
    loading: true
}

const tasktypes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TASKS_TYPES_SUCCESS:
            return {
                ...state,
                tasktypes: action.payload,
                loading: true
            }
        case GET_TASKS_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case ADD_TASK_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case ADD_TASK_TYPES_SUCCESS:
            return {
                ...state,
                tasktype: action.payload,
                error: null,
            };
        case UPDATE_TASK_TYPES_SUCCESS:
            return {
                ...state,
                tasktype: action.payload,
                error: null,
            };
        case UPDATE_TASK_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_TASKTYPE_CONFIG_LIST_SUCCESS:
            return {
                ...state,
                tasktypes_configlist: action.payload,
                error: null,
            };
        case GET_TASKTYPE_CONFIG_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_TASK_TYPE_CONFIG_SUCCESS:
            return {
                ...state,
                configitem: action.payload,
                error: null,
            };
        case ADD_TASK_TYPE_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case UPDATE_TASK_TYPE_CONFIG_SUCCESS:
            return {
                ...state,
                configitem: action.payload,
                error: null,
            };
        case UPDATE_TASK_TYPE_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_TASK_TYPES_SUCCESS:
            return {
                ...state,
                tasktypes: state.tasktypes.filter(
                    task_type => task_type.task_type_id.toString() !== action.payload.toString()
                ),
            };

        case DELETE_TASK_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_TASK_TYPE_CONFIG_SUCCESS:
            return {
                ...state,
                tasktypes_configlist: state.tasktypes_configlist.filter(
                    field_item => field_item.field_id.toString() !== action.payload.toString()
                ),
            };

        case DELETE_TASK_TYPE_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default tasktypes