import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import resetPasswordSaga from "./auth/resetpwd/saga";
import LayoutSaga from "./layout/saga";

import ContactSaga from "./contacts/saga"
import workflowsSaga from "./workflows/saga";
import tasktypeSaga from "./tasktypes/saga";
import wf_taskConfigurationSaga from "./taskconfiguration/saga";
import dashboardSaga from "./dashboard/saga";
import changePasswordSaga from "./auth/changepassword/saga";
import filemanagerSaga from "./filemanager/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ContactSaga),    
    fork(workflowsSaga),
    fork(tasktypeSaga),
    fork(wf_taskConfigurationSaga),
    fork(resetPasswordSaga),
    fork(dashboardSaga),
    fork(changePasswordSaga),
    fork(filemanagerSaga)
  ]);
}
