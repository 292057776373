import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: user,
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const verifyOtp = (data) => ({
  type: VERIFY_OTP,
  payload: data,
});

export const verifyOtpSuccess = () => ({
  type: VERIFY_OTP_SUCCESS,
});

export const verifyOtpError = (error) => ({
  type: VERIFY_OTP_ERROR,
  payload: error,
});


