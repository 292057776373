// reducer.js
import {
    FETCH_DASHBOARD_DATA,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_DASHBOARD_DATA_FAILURE,
  } from "./actionTypes";
  
  const initialState = {
    loading: false,
    dashboardData: null,
    error: null,
  };
  
  const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DASHBOARD_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          dashboardData: action.payload,
        };
      case FETCH_DASHBOARD_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;
  