import { success } from "toastr"
import {
    UPDATE_WORKFLOW_TASK_CONFIG_SUCCESS,
    UPDATE_WORKFLOW_TASK_CONFIG_FAIL,
    GET_WORKFLOW_TASK_CONFIG_SUCCESS,
    GET_WORKFLOW_TASK_CONFIG_FAIL
} from "./actionTypes"

const INIT_STATE = {
    error: {},
    wf_taskConfiguration: [],
    loading: true,
    success:false
}

const wf_taskConfiguration = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_WORKFLOW_TASK_CONFIG_SUCCESS:
                return {
                  ...state,
                  taskConfiguration : action.payload,
                  success:true
                }
        case UPDATE_WORKFLOW_TASK_CONFIG_FAIL:
                return{
                  ...state,
                    error: action.payload,
                    success:false
                }
        case GET_WORKFLOW_TASK_CONFIG_SUCCESS:
                return {
                    ...state,
                    wf_taskConfiguration: action.payload,
                    error: null,
                    };
        case GET_WORKFLOW_TASK_CONFIG_FAIL:
                return {
                    ...state,
                    error: action.payload,
                };        
        default:
            return state        
    }

}

export default wf_taskConfiguration