import React, {  useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Worker, Viewer, ToolbarProps } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import  fetch_pdf_file from "../../helpers/fileservice";
import CryptoJS from 'crypto-js';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import demoFile from '../../assets/documents/Charan-Chunchu-Resume.pdf';

const buttonStyle = {
    backgroundColor: "#357edd",
    border: "none",
    borderRadius: "4px",
    color: "#ffffff",
    cursor: "pointer",
    padding: "8px",
};

const secretKey = process.env.REACT_APP_SECRETKEY;

const PDF_File_ViewerPage = () => {
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    // Get bucket and object key from URL query parameters
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('data');
    
    const decryptQueryString = (encryptedData) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
            const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decrypted;
        } catch (error) {
            throw new Error('Failed to decrypt data');
        }
    };

    useEffect(() => {
        let blobUrl = null;  // Initialize blobUrl to null
        if (encryptedData) {debugger;
            try {
                // Decrypt the query parameters
                const { bucket, objectKey } = decryptQueryString(encryptedData);
    
                const fetchPdf = async () => {
                    try {
                        console.log("Bucket Name:", bucket);
                        console.log("Object Key:", objectKey);
    
                        // Fetch the PDF using bucket and objectKey
                        const response = await fetch_pdf_file(bucket, objectKey);
                        blobUrl = URL.createObjectURL(response);  // Create Blob URL for the PDF
                        setPdfBlobUrl(blobUrl);  // Update state with Blob URL
                    } catch (error) {
                        console.error('Error fetching PDF:', error);
                        setError('Failed to load PDF');
                    }
                };
    
                // Call the asynchronous function to fetch PDF
                fetchPdf();
            } catch (err) {
                console.error('Decryption error:', err);
                setError('Invalid or corrupted data');
            }
        } else {
            setError('No data provided');
        }
    
        // Cleanup function to revoke the Blob URL when the component unmounts or URL changes
        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);  // Clean up Blob URL
            }
        };
    }, [encryptedData]);
    
    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const { ZoomOut, ZoomIn, GoToNextPage, GoToPreviousPage, CurrentPageInput, TotalPages } = slots;
                return (
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            padding: "10px",
                            backgroundColor: "#f1f1f1"
                        }}
                    >
                        {/* Zoom Out Button */}
                        <div style={{ marginRight: "8px" }}>
                            <ZoomOut>
                                {(props) => (
                                    <button
                                        style={buttonStyle}
                                        onClick={props.onClick}
                                    >
                                        Zoom out
                                    </button>
                                )}
                            </ZoomOut>
                        </div>

                        {/* Zoom In Button */}
                        <div style={{ marginRight: "8px" }}>
                            <ZoomIn>
                                {(props) => (
                                    <button
                                        style={buttonStyle}
                                        onClick={props.onClick}
                                    >
                                        Zoom in
                                    </button>
                                )}
                            </ZoomIn>
                        </div>

                        {/* Previous Page Button */}
                        <div style={{ marginRight: "8px" }}>
                            <GoToPreviousPage>
                                {(props) => (
                                    <button
                                        style={buttonStyle}
                                        onClick={props.onClick}
                                    >
                                        Previous Page
                                    </button>
                                )}
                            </GoToPreviousPage>
                        </div>

                        {/* Current Page Number */}
                        <div style={{ marginRight: "8px" }}>
                            <CurrentPageInput />
                        </div>

                        {/* Next Page Button */}
                        <div style={{ marginRight: "8px" }}>
                            <GoToNextPage>
                                {(props) => (
                                    <button
                                        style={buttonStyle}
                                        onClick={props.onClick}
                                    >
                                        Next Page
                                    </button>
                                )}
                            </GoToNextPage>
                        </div>

                        {/* Total Pages */}
                        <div style={{ marginRight: "8px" }}>
                            <TotalPages>
                                {(props) => (
                                    <span>of {props.numPages}</span>
                                )}
                            </TotalPages>
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );


    
    //workerUrl= {window.location.origin + "/pdf.worker.min.mjs"}
    return (

        <div style={{ height: '100vh', width: '100%', paddingTop: '100px' }}>
             {pdfBlobUrl ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={pdfBlobUrl}
                    plugins={[defaultLayoutPluginInstance]}
                    renderToolbar={renderToolbar}
                    defaultScale={1.0}  /* Set to 100% zoom */ />
            </Worker>
            ) : (
                <div>Loading PDF...</div>
            )}
        </div>

    );
}

export default PDF_File_ViewerPage;
