import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";
import ChangePasswordScreen  from "../pages/Authentication/change-password";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/reset-password";
import ActivateAccount from "../pages/Authentication/activate-account";


// Dashboard
import ModulePage from "../pages/Modules/ModulePage";
import Dashboard from "../pages/Dashboard/index";

// Admin
import ContactsList from "../pages/Contacts/ContactList/contacts-list";

//tasktypes
import TaskTypesList from "../pages/TaskTypes/TaskTypesList/tasktypes-list";
import TasksTypesCreate from "../pages/TaskTypes/TaskDetails/taskcreate";

// workflows
import WorkflowBuilder from "../pages/Workflow/WorkflowBuilder";
import WorkflowsList from "../pages/Workflow/WorkflowsList";

//Tablebuilder
import TablesList from "../pages/TableBuilder/TablesList";
import CustomTableBuilder from "pages/TableBuilder/CustomTableBuilder";

// File Manager
import FilesList from "../pages/FileManager/FilesList";
import FileViewerPage from "pages/FileManager/FileViewerPage";
import FileManagerFiltersList from "pages/FileManagerFilters/FileManagerFiltersList";
import PDF_File_ViewerPage from "pages/FileManager/file_pdf-viewer";
import DocumentFileViewerPage from "../pages/FileManager/FileDocViewerPage";
import TextFileViewerPage from "../pages/FileManager/FileTextDocViewerPage";


import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";

const authProtectedRoutes = [
  // { path: "/module", component: <ModulePage /> },
  { path: "/dashboard", component: <Dashboard /> },
  //profile
  { path: "/profile", component: <UserProfile /> }, 
  { path: "/change-password", component : <ChangePasswordScreen/>},
  
  // Admin 
  { path: "/contacts", component: <ContactsList/>},
  //tasks
  { path: "/tasktypes", component: <TaskTypesList/>},
  { path: "/tasks-create", component: <TasksTypesCreate/>},

  // workflows
  { path: "/workflow", component: <WorkflowBuilder/>},
  { path:"/workflows-list", component:<WorkflowsList />},
  // { path: "/workflow/Edit/:id", component: <WorkflowBuilder/>},
  { path:"/workflows/get_workflow_config", component: <WorkflowBuilder/>},
  { path: "/tableslist", component: <TablesList/>},
  { path:"/custom-table", component: <CustomTableBuilder/>},
  { path: "/fileslist", component: <FilesList/>},
  { path: "/file-detail-viewer/:id", component: <FileViewerPage /> },
  { path:"/filemanager-filterslist", component:<FileManagerFiltersList />},
  { path: "/pdf-viewer",component:<PDF_File_ViewerPage/>},
  { path: "/document-viewer", component:<DocumentFileViewerPage />},
  { path: "/text-viewer", component: <TextFileViewerPage/>},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component:<ResetPassword/>},
  { path: "/activate", component:<ActivateAccount/>},
  { path: "/login/callback", component:<LoginCallback/>}
];

export { authProtectedRoutes, publicRoutes };
