import {
    GET_WORKFLOWS,
    GET_WORKFLOWS_SUCCESS,
    GET_WORKFLOWS_FAIL,
    ADD_NEW_WORKFLOW,
    ADD_WORKFLOW_SUCCESS,
    ADD_WORKFLOW_FAIL,
    UPDATE_WORKFLOW,
    UPDATE_WORKFLOW_SUCCESS,
    UPDATE_WORKFLOW_FAIL,
    DELETE_WORKFLOW,
    DELETE_WORKFLOW_SUCCESS,
    DELETE_WORKFLOW_FAIL,
    GET_WORKFLOW_CONFIG,
    GET_WORKFLOW_CONFIG_SUCCESS,
    GET_WORKFLOW_CONFIG_FAIL,
    UPDATE_WORKFLOW_CONFIG,
    UPDATE_WORKFLOW_CONFIG_SUCCESS,
    UPDATE_WORKFLOW_CONFIG_FAIL,
    DELETE_WORKFLOW_NODE_CONFIG,
    DELETE_WORKFLOW_NODE_CONFIG_SUCCESS,
    DELETE_WORKFLOW_NODE_CONFIG_FAIL
    
} from "./actionTypes"

  export const getWorkflows = () => ({
    type: GET_WORKFLOWS,
  })
  
  export const getWorkflowsSuccess = workflow => ({
    type: GET_WORKFLOWS_SUCCESS,
    payload: workflow,
  })
  
  export const getWorkflowsFail = error => ({
    type: GET_WORKFLOWS_FAIL,
    payload: error,
  })

  export const addNewWorkflow = workflow => ({
    type: ADD_NEW_WORKFLOW,
    payload: workflow,
  })
  
  export const addWorkflowSuccess = workflow => ({
    type: ADD_WORKFLOW_SUCCESS,
    payload: workflow,
  })
  
  export const addWorkflowFail = error => ({
    type: ADD_WORKFLOW_FAIL,
    payload: error,
  })
  
  export const updateWorkflow = workflow => ({
    type: UPDATE_WORKFLOW,
    payload: workflow,
  })
  
  export const updateWorkflowSuccess = workflow => ({
    type: UPDATE_WORKFLOW_SUCCESS,
    payload: workflow,
  })
  
  export const updateWorkflowFail = error => ({
    type: UPDATE_WORKFLOW_FAIL,
    payload: error,
  })
  
  export const deleteWorkflow = workflow => ({
    type: DELETE_WORKFLOW,
    payload: workflow,
  })
  
  export const deleteWorkflowSuccess = workflow => ({
    type: DELETE_WORKFLOW_SUCCESS,
    payload: workflow,
  })
  
  export const deleteWorkflowFail = error => ({
    type: DELETE_WORKFLOW_FAIL,
    payload: error,
  })

  // update and saving configdetails
  export const getWorkflowConfig = configId => ({
    type: GET_WORKFLOW_CONFIG,
    payload:configId
  })
 
  export const getWorkflowConfigSuccess = workflow => ({
    type: GET_WORKFLOW_CONFIG_SUCCESS,
    payload: workflow,
  })
  
  export const getWorkflowConfigFail = error => ({
    type: GET_WORKFLOWS_FAIL,
    payload: error,
  })


  export const updateWorkflowConfig = payload =>({
    type: UPDATE_WORKFLOW_CONFIG,
    payload: payload,
  })

  export const updateWorkflowConfigSuccess = configdetails =>({
    type: UPDATE_WORKFLOW_CONFIG_SUCCESS,
    payload: configdetails,
  })

  export const updateWorkflowConfigFail = error =>({
    type: UPDATE_WORKFLOW_CONFIG_FAIL,
    payload:error,
  })

  export const deleteWorkflowNodeConfig = payload =>({
    type: DELETE_WORKFLOW_NODE_CONFIG,
    payload:payload,
  })

  export const deleteWorkflowNodeConfigSuccess = payload =>({
    type: DELETE_WORKFLOW_NODE_CONFIG_SUCCESS,
    payload: payload,
  })

  export const deleteWorkflowNodeConfigFail = error => ({
    type: DELETE_WORKFLOW_NODE_CONFIG_FAIL,
    payload: error,
  })






  
