import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  accountValidateError:null,
  accountValidate:false
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        success: false,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        success: false,
        registrationError: action.payload,
      }
      break
      case ACTIVATE_ACCOUNT_REQUEST:
      state = {
        ...state,
        loading: true,
        accountValidate: false,
        accountValidateError: null,
      }
      break
      case ACTIVATE_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountValidate: true,
        accountValidateError: null,
      }
      break
      case ACTIVATE_ACCOUNT_FAILURE:
      state = {
        ...state,
        loading: false,
        accountValidate: false,
        accountValidateError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
