import {
    GET_WORKFLOWS_SUCCESS,
    GET_WORKFLOWS_FAIL,
    ADD_WORKFLOW_SUCCESS,
    ADD_WORKFLOW_FAIL,
    UPDATE_WORKFLOW_SUCCESS,
    UPDATE_WORKFLOW_FAIL,
    DELETE_WORKFLOW_SUCCESS,
    DELETE_WORKFLOW_FAIL,
    GET_WORKFLOW_CONFIG_SUCCESS,
    GET_WORKFLOW_CONFIG_FAIL,
    DELETE_WORKFLOW_NODE_CONFIG_SUCCESS,
    DELETE_WORKFLOW_NODE_CONFIG_FAIL
} from "./actionTypes"

const INIT_STATE = {
    error: {},
    workflows: [],
    taskConfiguration: [],
    workflowConfig:null,
    loading: true
}

const workflows = (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case GET_WORKFLOWS_SUCCESS:
            return {
                ...state,
                workflows: action.payload,
                loading: true
            }
        case GET_WORKFLOWS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
           
              case ADD_WORKFLOW_SUCCESS:
                return {
                  ...state,
                  workflows: [action.payload, ...state.workflows],
                };
          
              case ADD_WORKFLOW_FAIL:
                return {
                  ...state,
                  error: action.payload,
                };          
              
          
              case UPDATE_WORKFLOW_SUCCESS:
                return {
                  ...state,
                  workflows: state.workflows.map(workflow =>
                    workflow.config_id.toString() === action.payload.config_id.toString()
                      ? { workflow, ...action.payload }
                      : workflow
                  ),
                };
          
              case UPDATE_WORKFLOW_FAIL:
                return {
                  ...state,
                  error: action.payload,
                };
          
              case DELETE_WORKFLOW_SUCCESS:
                return {
                  ...state,
                  workflows: state.workflows.filter(workflow => workflow.config_id !== action.payload.config_id)
                };
          
              case DELETE_WORKFLOW_FAIL:
                return {
                  ...state,
                  error: action.payload,
                };
                case GET_WORKFLOW_CONFIG_SUCCESS:
                  return {
                    ...state,
                    workflowConfig: action.payload,
                  };
            
                case GET_WORKFLOW_CONFIG_FAIL:
                  return {
                    ...state,
                    error: action.payload,
                  };
                case DELETE_WORKFLOW_NODE_CONFIG_SUCCESS:
                  return {
                    ...state,
                  };                     
        default:
            return state
    }
}

export default workflows