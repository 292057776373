/* Workflows list */

export const GET_WORKFLOWS = "GET_WORKFLOWS"
export const GET_WORKFLOWS_SUCCESS = "GET_WORKFLOWS_SUCCESS"
export const GET_WORKFLOWS_FAIL = "GET_WORKFLOWS_FAIL"

/**
 * Add New workflow
 */
export const ADD_NEW_WORKFLOW = "ADD_NEW_WORKFLOW"
export const ADD_WORKFLOW_SUCCESS = "ADD_WORKFLOW_SUCCESS"
export const ADD_WORKFLOW_FAIL = "ADD_WORKFLOW_FAIL"

/**
 * Edit Workflow
 */
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW"
export const UPDATE_WORKFLOW_SUCCESS = "UPDATE_WORKFLOW_SUCCESS"
export const UPDATE_WORKFLOW_FAIL = "UPDATE_WORKFLOW_FAIL"

/**
 * Delete Workflow
 */
export const DELETE_WORKFLOW = "DELETE_WORKFLOW"
export const DELETE_WORKFLOW_SUCCESS = "DELETE_WORKFLOW_SUCCESS"
export const DELETE_WORKFLOW_FAIL = "DELETE_WORKFLOW_FAIL"

// Add/update Workflowconfig

export const GET_WORKFLOW_CONFIG = "GET_WORKFLOW_CONFIG"
export const GET_WORKFLOW_CONFIG_SUCCESS = "GET_WORKFLOW_CONFIG_SUCCESS"
export const GET_WORKFLOW_CONFIG_FAIL = "GET_WORKFLOW_CONFIG_FAIL"


export const UPDATE_WORKFLOW_CONFIG = "UPDATE_WORKFLOW_CONFIG"
export const UPDATE_WORKFLOW_CONFIG_SUCCESS = "UPDATE_WORKFLOW_CONFIG_SUCCESS"
export const UPDATE_WORKFLOW_CONFIG_FAIL = "UPDATE_WORKFLOW_CONFIG_FAIL"

export const DELETE_WORKFLOW_NODE_CONFIG = "DELETE_WORKFLOW_NODE_CONFIG"
export const DELETE_WORKFLOW_NODE_CONFIG_SUCCESS = "DELETE_WORKFLOW_NODE_CONFIG_SUCCESS"
export const DELETE_WORKFLOW_NODE_CONFIG_FAIL = "DELETE_WORKFLOW_NODE_CONFIG_FAIL"
