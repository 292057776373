import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components

import CardWorkflow from "./card-workflow";
import MiniWidget from "./mini-widget";

//i18n
import { withTranslation } from "react-i18next";

const Dashboard = props => {
  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Total Users",
      value: "30",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-archive-in",
      title: "Active Users",
      value: "20",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Inactive Users",
      value: "10",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ]
  //meta title
  document.title = "DataOS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("Dashboard")} />
          
          <Row>
            <CardWorkflow />
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
