import {
    GET_TASKS_TYPES,
    GET_TASKS_TYPES_SUCCESS,
    GET_TASKS_TYPES_FAIL,
    ADD_NEW_TASK_TYPES,
    ADD_TASK_TYPES_SUCCESS,
    ADD_TASK_TYPES_FAIL,
    UPDATE_TASK_TYPES,
    UPDATE_TASK_TYPES_SUCCESS,
    UPDATE_TASK_TYPES_FAIL,
    DELETE_TASK_TYPES,
    DELETE_TASK_TYPES_FAIL,
    DELETE_TASK_TYPES_SUCCESS,
    FETCH_PARENT_TASKTYPES,
    FETCH_PARENT_TASKTYPES_SUCCESS,
    FETCH_PARENT_TASKTYPES_FAILURE,
    GET_TASKTYPE_CONFIG_LIST,
    GET_TASKTYPE_CONFIG_LIST_FAIL,
    GET_TASKTYPE_CONFIG_LIST_SUCCESS,
    ADD_TASK_TYPE_CONFIG,
    ADD_TASK_TYPE_CONFIG_SUCCESS,
    ADD_TASK_TYPE_CONFIG_FAIL,
    UPDATE_TASK_TYPE_CONFIG_SUCCESS,
    UPDATE_TASK_TYPE_CONFIG_FAIL,
    UPDATE_TASK_TYPE_CONFIG,
    DELETE_TASK_TYPE_CONFIG,
    DELETE_TASK_TYPE_CONFIG_SUCCESS,
    DELETE_TASK_TYPE_CONFIG_FAIL

} from "./actionTypes"

export const getTasksTypes = () => ({
    type: GET_TASKS_TYPES,
})
  
  export const getTasksTypesSuccess = tasks => ({
    type: GET_TASKS_TYPES_SUCCESS,
    payload: tasks,
  })
  
  export const getTasksTypesFail = error => ({
    type: GET_TASKS_TYPES_FAIL,
    payload: error,
  })

  export const addNewTaskTypes = task => ({
    type: ADD_NEW_TASK_TYPES,
    payload: task,
  })
  
  export const addTaskTypesSuccess = tasktype => ({
    type: ADD_TASK_TYPES_SUCCESS,
    payload: tasktype,
  })
  
  export const addTaskTypesFail = error => ({
    type: ADD_TASK_TYPES_FAIL,
    payload: error,
  })

  export const updateTaskTypes = task => ({
    type: UPDATE_TASK_TYPES,
    payload: task,
  })
  
  export const updateTaskTypesSuccess = task => ({
    type: UPDATE_TASK_TYPES_SUCCESS,
    payload: task,
  })
  
  export const updateTaskTypesFail = error => ({
    type: UPDATE_TASK_TYPES_FAIL,
    payload: error,
  })

  export const deleteTaskTypes = (task_type_id) => ({
    type: DELETE_TASK_TYPES,
    payload:{task_type_id}
  })
  
  export const deleteTaskTypesSuccess = task_type => ({
    type: DELETE_TASK_TYPES_SUCCESS,
    payload: task_type,
  })
  
  export const deleteTaskTypesFail = error => ({
    type: DELETE_TASK_TYPES_FAIL,
    payload: error,
  })

  export const fetchParentTaskTypes = () => ({
    type: FETCH_PARENT_TASKTYPES,
  })
  
  export const fetchParentTaskTypesSuccess = parentstasktypes => ({
    type: FETCH_PARENT_TASKTYPES_SUCCESS,
    payload: parentstasktypes,
  })
  
  export const fetchParentTaskTypesFailure = error => ({
    type: FETCH_PARENT_TASKTYPES_FAILURE,
    payload: error,
  })

  export const getTaskTypeConfigList = (taskTypeId) => ({
    type: GET_TASKTYPE_CONFIG_LIST,
    payload: taskTypeId,
});

export const getTaskTypeConfigListSuccess = (configList) => ({
    type: GET_TASKTYPE_CONFIG_LIST_SUCCESS,
    payload: configList,
});

export const getTaskTypeConfigListFail = (error) => ({
    type: GET_TASKTYPE_CONFIG_LIST_FAIL,
    payload: error,
});

export const addTaskTypeConfig = (configitem) => ({
  type: ADD_TASK_TYPE_CONFIG,
  payload: configitem,
})

export const addTaskTypeConfigSuccess = (configitem) => ({
  type: ADD_TASK_TYPE_CONFIG_SUCCESS,
  payload: configitem,
})

export const addTaskTypeConfigFail = (error) => ({
  type: ADD_TASK_TYPE_CONFIG_FAIL,
  payload: error,
})

export const updateTaskTypeConfig = (configitem) => ({
  type: UPDATE_TASK_TYPE_CONFIG,
  payload: configitem,
})

export const updateTaskTypeConfigSuccess = (configitem) => ({
  type: UPDATE_TASK_TYPE_CONFIG_SUCCESS,
  payload: configitem,
})

export const updateTaskTypeConfigFail = error => ({
  type: UPDATE_TASK_TYPE_CONFIG_FAIL,
  payload: error,
})

export const deleteTaskTypeField = (field_id) => ({
  type: DELETE_TASK_TYPE_CONFIG,
  payload:{field_id}
})

export const deleteTaskTypeFieldSuccess = task_type_config => ({
  type: DELETE_TASK_TYPE_CONFIG_SUCCESS,
  payload: task_type_config,
})

export const deleteTaskTypeFieldFail = error => ({
  type: DELETE_TASK_TYPE_CONFIG_FAIL,
  payload: error,
})


