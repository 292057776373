import React, { useEffect, useMemo, useState } from "react";
import TableContainer from 'components/Common/TableContainer';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
    Button,
    Collapse,
    Label,
    Input,
    Form,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import FileViewer from 'react-file-viewer';
import { useSelector, useDispatch } from "react-redux";
import {
    getFiles as onGetFiles,
    getFilteredFiles as onGetFilteredFiles,
    getCustomFiltersList as onGetCustomFiltersList,
    getCustomFilterMetaData as onGetCustomFilterMetaData,
    updateCustomFilterMetaData as onUpdateCustomFilterMetaData
} from "store/actions";
import { isEmpty } from "lodash";
import { createSelector } from "reselect";
import CryptoJS from 'crypto-js';
import fetch_custom_filters_with_options from "helpers/fileCustomFilterService";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from 'react-toastify';
import { getLoggedInUser } from "../../helpers/fakebackend_helper";


const FilesList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Use the navigate function
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [searchFields, setSearchFields] = useState(() => {
        // Initialize searchFields from localStorage, or empty if none
        const savedSearchFields = localStorage.getItem('searchFields');
        return savedSearchFields ? JSON.parse(savedSearchFields) : {};
    });
    const [isFiltered, setIsFiltered] = useState(false);  // Flag to track if filtered files are being viewed
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [filters, setFilters] = useState([])
    const [modal, setModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);

    const toggleModal = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }

    // Fetch logged-in user when the component mounts
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await getLoggedInUser();
                setLoggedInUser(user);
            } catch (error) {
                console.error("Error fetching logged in user", error);
            }
        };
        fetchUser();
    }, []);

    // Fetch the custom filters on component mount
    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const fetchedFilters = await fetch_custom_filters_with_options();
                console.log(fetchedFilters.Data)
                setFilters(fetchedFilters.Data);  // Set filters state with fetched data

            } catch (error) {
                console.error("Error fetching filters: ", error);
            }
        };
        fetchFilters();
    }, []);


    useEffect(() => {
        // Reset searchFields when the component mounts (or page reloads)
        if (!isFiltered) {
            setIsFiltered(false);
            setSearchFields({});
            localStorage.removeItem('searchFields');
            // Trigger file fetching after clearing filters
            dispatch(onGetFiles());
        }
    }, [isFiltered, dispatch]);

    const filemanagerProperties = createSelector(
        (state) => state.filemanager,
        (filemanager) => ({
            files: filemanager.files,
            loading: filemanager.loading,
            metadata: filemanager.metadata
        })
    );

    const { files, loading, metadata } = useSelector(filemanagerProperties);

    const [metaDataRows, setMetaDataRows] = useState(metadata || []);
    const [isLoading, setIsLoading] = useState(loading);

    const secretKey = process.env.REACT_APP_SECRETKEY;

    useEffect(() => {
        if (loggedInUser && !isFiltered && isEmpty(files)) {
            dispatch(onGetFiles(loggedInUser.user_id));  // Fetch default files initially only if not filtering
        }
    }, [dispatch, files, isFiltered, loggedInUser]);


    const encryptQueryString = (bucket, objectKey) => {
        const params = { bucket, objectKey };
        // Encrypt the parameters
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(params), secretKey).toString();
        return encrypted;
    };

    const handleViewFileClick = (file) => {
        const fileExtension = file.object_key.split('.').pop().toLowerCase();
        // Construct the query string using the file's bucket and object key
        const encryptedParams = encryptQueryString(file.bucket_name, file.object_key);
        if (fileExtension === 'pdf') {
            window.open(`/pdf-viewer?data=${encodeURIComponent(encryptedParams)}`, '_blank');
        }
        else if (fileExtension === 'xlsx') {
            window.open(`/document-viewer?data=${encodeURIComponent(encryptedParams)}&type=${fileExtension}`, '_blank');
        }
        else {
            window.open(`/text-viewer?data=${encodeURIComponent(encryptedParams)}&type=${fileExtension}`, '_blank');
        }
    };



    // Handle refresh click to reload files
    const handleRefreshClick = () => {
        setIsFiltered(false);  // Reset the filter flag
        dispatch(onGetFiles()); // Dispatch the action to refresh files
        setSearchFields({});
        localStorage.removeItem('searchFields'); // Clear search fields from localStorage
    };

    // Handle input change for the search fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchFields({
            ...searchFields,
            [name]: value  // Update specific search field
        });
    };


    const handleSearchClick = () => {
        const formattedSearchFields = Object.keys(searchFields).reduce((acc, key) => {
            let value = searchFields[key];

            // Convert empty string to null
            if (value === '') {
                value = null;
            }

            // Find the field type based on the key (assuming you have a `filters` array or similar)
            const filter = filters.find(f => f.attribute_key === key);

            // Format date if the key contains "date" or the field type is "Date"
            if ((key.toLowerCase().includes("date") || filter?.attribute_type === "Date") && value) {
                value = value.replace(/-/g, ""); // Format YYYY-MM-DD to YYYYMMDD
            }

            acc[key] = value;
            console.log(acc)
            return acc;
        }, {});

        dispatch(onGetFilteredFiles(formattedSearchFields));
        setIsFiltered(true);
        localStorage.setItem('searchFields', JSON.stringify(formattedSearchFields));
    };

    const renderFilterInput = (filter) => {
        const { attribute_key, attribute_name, attribute_type, attribute_id, attribute_options } = filter;
        switch (attribute_type) {
            case "Text":
                return (
                    <Input
                        type="text"
                        name={attribute_key}
                        id={`filter_${attribute_id}`}
                        value={searchFields[attribute_key] || ''}
                        onChange={handleInputChange}
                    />
                );
            case "Dropdown":
                //const options = attribute_options || [];
                const options = attribute_key === 'client'
                    ? (loggedInUser.client_ids.includes('All') ? (attribute_options || []) :
                       (attribute_options || []).filter(option => loggedInUser.client_ids.includes(option))
                    ) : (attribute_options || []);
                return (
                    <Input
                        type="select"
                        name={attribute_key}
                        id={`filter_${attribute_id}`}
                        value={searchFields[attribute_key] || ''}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        {options.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Input>
                );
            case "Date":
                return (
                    <Input
                        type="date"
                        name={attribute_key}
                        id={`filter_${attribute_id}`}
                        value={searchFields[attribute_key] || ''}
                        onChange={handleInputChange}
                    />
                );
            default:
                return null;
        }
    };

    const columns = useMemo(
        () => [

            {
                header: "File Name",
                accessorKey: "file_name",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Type",
                accessorKey: "file_type",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const fileType = cellProps.row.original.file_type;
                    const typeMapping = {
                        "pdf": { label: "PDF", color: "danger" },      // Red for PDF
                        "zip": { label: "ZIP", color: "secondary" },   // Grey for ZIP
                        "jpeg": { label: "Image", color: "primary" },  // Blue for JPEG
                        "txt": { label: "Text", color: "info" },   // Green for Text
                        "jpg": { label: "JPG", color: "primary" },    // Blue for JPG
                        "xlsx": { label: "XLSX", color: "success" },
                        "csv": { label: "CSV", color: "success" },       // Light blue for CSV
                    };
                    const typeInfo = typeMapping[fileType] || { label: "Other", color: "dark" }; // Default to "dark" if type not found
                    return (
                        // <span className={`badge badge-soft-${typeMapping[type] ? "info" : "warning"}`}>
                        //     {typeMapping[type] || "Other"}
                        // </span>
                        <span className={`badge badge-soft-${typeInfo.color}`}>
                            {typeInfo.label}
                        </span>
                    );
                },
            },
            {
                header: 'Uploaded Date',
                accessorKey: "event_time",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li>
                            <Button
                                size="sm"
                                className="btn-soft-primary"
                                onClick={() => handleViewFileClick(cellProps.row.original)}
                                id={`viewtooltip-${cellProps.row.original.id}`}
                            >
                                <i className="mdi mdi-eye-outline" />
                            </Button>
                            <UncontrolledTooltip
                                placement="top"
                                target={`viewtooltip-${cellProps.row.original.id}`}
                            >
                                View
                            </UncontrolledTooltip>
                        </li>
                        <li>
                            <Button
                                size="sm"
                                className="btn-soft-success"
                                onClick={() => handleMetaDataClick(cellProps.row.original)}
                                id={`metadata-tooltip-${cellProps.row.original.id}`}
                            >
                                <i className="mdi mdi-cog" />
                            </Button>
                            <UncontrolledTooltip
                                placement="top"
                                target={`metadata-tooltip-${cellProps.row.original.id}`}
                            >
                                Configure Meta Data
                            </UncontrolledTooltip>
                        </li>
                    </ul>
                ),
            },
        ],
        []
    );

    const handleMetaDataClick = (file) => {
        setSelectedFileId(file.id)
        setModal(true);
        dispatch(onGetCustomFilterMetaData(file.id));
    }
    useEffect(() => {
        if (metadata) {
            setMetaDataRows(metadata);
        }
    }, [metadata]);

    const validationSchema = Yup.object().shape({
        metaDataRows: Yup.array().of(
            Yup.object().shape({
                attribute_name: Yup.string().required("Name is required"),
                attribute_key: Yup.string().required("Key is required"),
                attribute_value: Yup.string().required("Value is required"),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            metaDataRows: metaDataRows,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values.metaDataRows, metaDataRows, selectedFileId)
            // newly chnaged payload
            const payload = {
                file_id: selectedFileId,
                attributes: values.metaDataRows
            };
            console.log(payload)
            //dispatch(onUpdateCustomFilterMetaData(selectedFileId, values.metaDataRows));
            dispatch(onUpdateCustomFilterMetaData(payload));
            toggleModal();
        },
    });

    const addNewRow = () => {
        formik.setFieldValue("metaDataRows", [
            ...formik.values.metaDataRows,
            { attribute_name: "", attribute_key: "", attribute_value: "" },
        ]);
    };
    const removeRow = (index) => {
        const newRows = [...formik.values.metaDataRows];
        newRows.splice(index, 1); // Remove the row at the specified index
        formik.setFieldValue("metaDataRows", newRows);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Files List" breadcrumbItem="My Files List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">File Lists</h5>
                                        <div className="flex-shrink-0">
                                            <Link onClick={handleRefreshClick} className="btn btn-light me-1">
                                                <i className="mdi mdi-refresh" />
                                            </Link>
                                            <Link to="#!" onClick={toggle} className="btn btn-success">
                                                <i className="bx bx-filter-alt"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* New row for the collapse */}
                                    <Collapse isOpen={isOpen} id="collapseExample">
                                        <div className="mt-3"> {/* Add margin-top to space out from the content above */}
                                            <Row className="g-3">
                                                {filters?.map((filter) => (
                                                    <Col key={filter.attribute_id} xxl={2} lg={2}>
                                                        <div>
                                                            <Label htmlFor={`filter_${filter.attribute_id}`} className="form-label">
                                                                {filter.attribute_name}
                                                            </Label>
                                                            {renderFilterInput(filter)}
                                                        </div>
                                                    </Col>
                                                ))}
                                                <Col style={{ paddingTop: "25px" }}>
                                                    <Button color="primary" onClick={handleSearchClick}>Search</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Collapse>
                                </CardBody>
                                <CardBody>
                                    {isLoading ? (
                                        <Spinners setLoading={setIsLoading} />
                                    ) :
                                        files && files.length > 0 ? (
                                            <TableContainer
                                                columns={columns}
                                                data={files || []} // Only pass the files array
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                SearchPlaceholder="Search for ..."
                                                isCustomPageSize={true}
                                                tableClass="workflow-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                theadClass="table-light"
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                pagination="pagination"
                                            />
                                        ) : (
                                            <div className="text-center py-4">
                                                <h5><strong>No records found.</strong></h5> {/* Display the message when no data is available */}
                                            </div>
                                        )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={modal} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal} tag="h4">
                        Configure Meta Data
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={formik.handleSubmit}>
                            {formik.values.metaDataRows.map((row, index) => (
                                <Row key={index} className="mb-3">
                                    <Col md={3}>
                                        <Label for={`attribute_name_${index}`}>Name</Label>
                                        <Input
                                            type="text"
                                            id={`attribute_name_${index}`}
                                            name={`metaDataRows[${index}].attribute_name`}
                                            onChange={formik.handleChange}
                                            value={row.attribute_name}
                                        />
                                        {formik.errors.metaDataRows?.[index]?.attribute_name && (
                                            <div className="text-danger">{formik.errors.metaDataRows[index].attribute_name}</div>
                                        )}
                                    </Col>
                                    <Col md={3}>
                                        <Label for={`attribute_key_${index}`}>Key</Label>
                                        <Input
                                            type="text"
                                            id={`attribute_key_${index}`}
                                            name={`metaDataRows[${index}].attribute_key`}
                                            onChange={formik.handleChange}
                                            value={row.attribute_key}
                                        />
                                        {formik.errors.metaDataRows?.[index]?.attribute_key && (
                                            <div className="text-danger">{formik.errors.metaDataRows[index].attribute_key}</div>
                                        )}
                                    </Col>
                                    <Col md={5}>
                                        <Label for={`attribute_value_${index}`}>Value</Label>
                                        <Input
                                            type="text"
                                            id={`attribute_value_${index}`}
                                            name={`metaDataRows[${index}].attribute_value`}
                                            onChange={formik.handleChange}
                                            value={row.attribute_value}
                                        />
                                        {formik.errors.metaDataRows?.[index]?.attribute_value && (
                                            <div className="text-danger">{formik.errors.metaDataRows[index].attribute_value}</div>
                                        )}
                                    </Col>
                                    {/* Trash Icon for Deleting New Rows */}
                                    <Col md={1} className="text-center" style={{ paddingTop: "25px" }}>
                                        <Link to="" onClick={() => removeRow(index)} className="btn btn-danger">
                                            <i className="bx bx-trash"></i>
                                        </Link>
                                    </Col>
                                </Row>
                            ))}

                            <Button type="button" color="secondary" onClick={addNewRow}>
                                Add New Row
                            </Button>
                            <div className="text-end mt-3">
                                <Button type="button" color="secondary" onClick={toggleModal}>
                                    Close
                                </Button>{' '}
                                <Button type="submit" color="primary">
                                    Save Changes
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
                <ToastContainer />
            </div>
        </React.Fragment>
    );
};

export default FilesList;
