// actions.js
import {
    FETCH_DASHBOARD_DATA,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_DASHBOARD_DATA_FAILURE,
  } from "./actionTypes";
  
  export const fetchDashboardData = (data) => ({
    type: FETCH_DASHBOARD_DATA,
    payload: data,
  });
  
  export const fetchDashboardDataSuccess = (data) => ({
    type: FETCH_DASHBOARD_DATA_SUCCESS,
    payload: data,
  });
  
  export const fetchDashboardDataFailure = (error) => ({
    type: FETCH_DASHBOARD_DATA_FAILURE,
    payload: error,
  });
  