import {
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
  } from "./actionTypes"

  const initialState = {
    resetError: null,
    resetSuccessMsg: null,
  }

  const ResetPassword = (state = initialState, action) => {
    switch (action.type) {
      case RESET_PASSWORD_SUCCESS:
        state = {
          ...state,
          resetError: null,
          resetSuccessMsg: action.payload,
        }
        break
      case RESET_PASSWORD_ERROR:
        state = {
          ...state,
          resetError: action.payload,
          resetSuccessMsg: null,
        }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default ResetPassword
  