import React, { useMemo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  Badge,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import TableContainer from "components/Common/TableContainer";
import {
  getWorkflows as onGetWorkflows,
  addNewWorkflow as onAddNewWorkflow,
  deleteWorkflow as onDeleteWorkflow,
  updateWorkflow as onUpdateWorkflow,
} from "../../store/workflows/actions";
import { getLoggedInUser } from "../../helpers/fakebackend_helper";

const TablesList = () => {
  // Meta title
  document.title = "My Workflows | DataOS - Workflow list Admin & Dashboard Template";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [workflow, setWorkflow] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [loggedInUser, setLoggedInUser] = useState({});
  const [isWorkflowsFetched, setIsWorkflowsFetched] = useState(false);

  // new code for adding the new workflow

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      config_id: (workflow && workflow.config_id) || "",
      data_source: (workflow && workflow.data_source) || "",
      description: (workflow && workflow.description) || "",
      is_active:  workflow?.is_active !== undefined ? (workflow.is_active ? "true" : "false") : "true", 
      created_dt: (workflow && workflow.created_dt) || "",
    },
    validationSchema: Yup.object({
      data_source: Yup.string().required(
        "Please Enter Workflow Name"
      ),
      description: Yup.string().required(
        "Please Enter Workflow Description"
      ),
      is_active: Yup.string().oneOf(["true", "false"]).required("Please select a status")
    }),
    onSubmit: (values) => {debugger;
      // Transform form values to match the expected payload structure
      const transformedPayload = {
        data_source: values.data_source,
        sub_source: "", // Assuming this is an empty string
        config_details: "{}",
        description: values.description,
        is_active: values.is_active === "true",
        company_id: loggedInUser.company_id, // Example company_id
        created_by: loggedInUser.user_id // Assuming this is an empty string
      };

      if (isEdit) {
        const updateWorkflow = {
          // id: workflow.id,         
          // data_source: values.data_source,
          // description: values.description,
          // status: values.status,          
          // created_dt: values.created_dt 
          config_id: values.config_id,
          ...transformedPayload,
        };
       
        // update workflow
        dispatch(onUpdateWorkflow(updateWorkflow));
      } else {
        const newWorkflow = {
          //id: Math.floor(Math.random() * (30 - 20)) + 20,
          // data_source: values["data_source"],
          // description: values["description"],
          // status: values["status"],         
          // created_dt: values["created_dt"]
          // id: Math.floor(Math.random() * (30 - 20)) + 20,
          ...transformedPayload,
        };
        
        // save new project
        dispatch(onAddNewWorkflow(newWorkflow));
      }
      toggle();
      setIsWorkflowsFetched(false);
    },
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getLoggedInUser();
        setLoggedInUser(user);
      } catch (error) {
        console.error("Error fetching logged in user", error);
      }
    };
    fetchUser();
  }, [dispatch])

  // useEffect(() => {
  //   const fetchCompanyId = async () => {
  //     const id = await getCompanyId(); // Call the function to get companyId
  //     setCompanyId(id);
  //     dispatch(onGetWorkflows());
  //   };

  //   fetchCompanyId();
  // }, [dispatch]);

  // Selector to get workflows and loading state
  const WorkflowProperties = createSelector(
    (state) => state.workflows,
    (Workflows) => ({
      workflows: Workflows?.workflows,
      loading: Workflows?.loading
    })
  );

  const { workflows, loading } = useSelector(WorkflowProperties);
  const toggle = () => {
    if (modal) {
      setModal(false);
      setWorkflow(null);
    } else {
      setModal(true);
    }
  }
  useEffect(() => {
    if (loggedInUser.user_id && loggedInUser.company_id && !isWorkflowsFetched) {
      dispatch(onGetWorkflows());
      setIsWorkflowsFetched(true);
      setIsEdit(false);
    }
  }, [dispatch, loggedInUser, isWorkflowsFetched]);

  // useEffect(() => {
  //   if (workflows && !workflows.length) {
  //     dispatch(onGetWorkflows());
  //     setIsEdit(false);
  //   }
  // }, [dispatch, workflows]);

  

  const handleWorkflowClick = arg => {debugger;
    const workflow = arg;
    setWorkflow(workflow);
    // setWorkflow({
    //   config_id: workflow?.config_id,
    //   data_source: workflow?.data_source,
    //   description: workflow?.description,
    //   is_active: workflow?.is_active,
    //   // color: workflow.color,
    //   created_dt: workflow?.created_dt
    // }, [dispatch, workflows]);

    setIsEdit(true);
    toggle();
  }

  const onClickDelete = (workflow) => {
    setWorkflow(workflow);
    setDeleteModal(true);
  };

  const handleDeleteWorkflow = () => {
    if (workflow && workflow.config_id) {
      const Workflowconfig = {
        config_id: workflow.config_id,
        updated_by: loggedInUser.user_id
      }
      dispatch(onDeleteWorkflow(Workflowconfig));
    }
    setDeleteModal(false);
  };

  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "config_id",
        cell: (cell) => (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {cell.row.original.data_source.charAt(0)}
            </span>
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Table Name",
        accessorKey: "data_source",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => (
          <>
            <h5 className="text-truncate font-size-14">
              <Link to={`/workflows/get_workflow_config?config_id=${cellProps.row.original.config_id}`} className="text-dark">
                {cellProps.row.original.data_source}
              </Link>
            </h5>
            <p className="text-muted mb-0">{cellProps.row.original.description}</p>
          </>
        ),
      },
      {
        header: "Created On",
        accessorKey: "created_dt",
        enableColumnFilter: false,
        enableSorting: true,
      },
    //   {
    //     header: "Status",
    //     accessorKey: "is_active",
    //     cell: (cell) => (
    //       <span
    //         className={
    //           cell.getValue() ? "badge bg-success" : "badge bg-danger"
    //         }
    //       >
    //         {cell.getValue() ? "Active" : "Inactive"}
    //       </span>
    //     ),
    //     enableColumnFilter: false,
    //     enableSorting: true,
    //   },
      {
        header: "Action",
        cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              // onClick={() => handleWorkflowClick(cellProps.row.original)}
              onClick={() => {
                const workflowData = cellProps.row.original;
                handleWorkflowClick(workflowData);
              }}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(cellProps.row.original)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            </Link>
            <Link             
              to="/custom-table"            
              className="text-success"
            >
              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const handleWorkflowClicks = () => {
    // console.log(workflowData)
    // handleWorkflowClick()
    setIsEdit(false);
    setWorkflow("");
    toggle();
  };


  // const handleWorkflowClicks = () => {
  //   navigate("/workflow")
  // }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteWorkflow}
        onCloseClick={() => setDeleteModal(false)}
        text="Are you sure you want to delete this workflow?"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Table LIst" breadcrumbItem="My Table Lists" />
          {isLoading ?
            <Spinners setLoading={setLoading} />
            : (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={workflows || []}
                        isGlobalFilter={true}
                        isAddButton={true}
                        isPagination={true}
                        isCustomPageSize={true}
                        // handleUserClick={() => navigate("/workflow")}
                        handleUserClick={handleWorkflowClicks}
                        buttonClass="btn btn-success btn-rounded"
                        buttonName=" Add New Table"
                        tableClass="workflow-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Workflow" : "Add Workflow"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Name</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        name="data_source"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.data_source || ""}
                        invalid={
                          validation.touched.data_source && validation.errors.data_source ? true : false
                        }
                      />
                      {validation.touched.data_source && validation.errors.data_source ? (
                        <FormFeedback type="invalid">{validation.errors.data_source}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Description</Label>
                      <span style={{ color: "red" }}>*</span>
                      <Input
                        name="description"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description && validation.errors.description ? true : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>

                    {/* <div className="mb-3">
                      <Label>Status</Label>
                      <Input
                        name="is_active"
                        id="status1"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.is_active}
                      >
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Input>
                      {validation.touched.is_active && validation.errors.is_active ? (
                        <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                      ) : null}
                    </div> */}
                   
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success"
                        type="submit"
                        className="save-user"
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(TablesList);
